import React from 'react'
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import colorsv1 from '../config/colorsv1';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { pathOr } from 'ramda';
import { getTitle } from '../helper/formatter';
import { useSelector } from 'react-redux';



const Timer = (props) => {
    const navigate = useNavigate()
    const policy = useSelector(state => state.Auth.policy)
    

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} style={{textAlign:'center',position:'relative',height:'500px'}}>
                 
                    <img style={{width:'300px',height:'300px',position:'absolute',top:'50%',left:'50%',transform: 'translate(-50%, -50%)'}}
                        src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/loader.png'>
                    </img>
                    <div style={{position:'absolute',top:'50%',left:'50%',transform: 'translate(-50%, -50%)'}}>
                        <CountdownCircleTimer
    isPlaying
                            duration={7}
                            strokeWidth={8}
    colors={['#569e0d', '#a2cf75', '#569e0d', '#a2cf75']}
                            colorsTime={[7, 5, 2, 0]}
                            onComplete={() => {
                                if (pathOr('', ['optFor'], policy) != 'assignment') {
                                    navigate('/tenure')
                                } else {
                                    navigate('/assignment')
                            }}}
  >
    {/* {({ remainingTime }) => remainingTime} */}
                        </CountdownCircleTimer>
                        </div>
                               
                    <div className='h2' style={{ margin: '24px 0 12px 0', color: colorsv1.primary.dark[800] }}>{"Application is processing"}</div>
                    <div className='body'>{"Do not close the app or hit back button it might take up to 1 min"}</div>
                </Paper>
            </Container>
        </Home>
    )
}

export default Timer
import React, { useEffect, useRef, useState } from 'react'
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate, useParams } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { generateOtp, validateOtp } from '../actions/authAction';
import { LOGOUT, SHOW_SNACK } from '../actions/types';



const OTP = (props) => {
    const navigate = useNavigate()
    const otpRef = useRef(null);
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch()
      const [seconds, setSeconds] = React.useState(30)
    const [minutes, setMinutes] = React.useState(0)
    const [canResend, setCanResend] = React.useState(false)
    
 React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
        if (minutes === 0 && seconds === 0) {
          setCanResend(true)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [canResend, seconds, minutes])

    const checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$")
    const isValidPolicy = useSelector(state => state.Auth.isValidPolicy)
    
    const { id } = useParams()
     useEffect(() => {
         //console.log("id", id)
         if(checkForHexRegExp.test(id)){
             dispatch(generateOtp({policyId:id}))
         } else {
              dispatch({
      type: LOGOUT,
              });
             dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: 'Invalid URL'
        }
      })
         }
     }, [dispatch, id])
    
        const otpVerification = async () => {
        const status = await dispatch(validateOtp({otp:otp,policyId:id}))
        if(status){
            navigate('/intro')
        }
        }
    
      const handleResend = async () => {
    if (minutes === 0) {
        dispatch(generateOtp({policyId:id}))

        setCanResend(false)
        setSeconds(30)
    }
      }
    
    const handleOtp = async code => {
        setOtp(code);
        if (code.length === 6) {
        //     debugger;
        //     if (otpRef.current)
        // otpRef.current.blur();
         const status = await dispatch(validateOtp({otp:code,policyId:id}))
        if(status){
            navigate('/intro')
        }
    }
  };
    
    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {"Login"}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 

                
                    
                    <div style={{textAlign:'center'}}>
                        <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/OTP.png'
                            alt={"logo"} 
                            loading="lazy" style={{width:'108px',height:'102px',alignItems:'center'}}
                        ></img>
                        <Typography className='regular' variant='subtitle2' style={{}}>
                            Enter  6 digit verfication code
                            sent to your registered mobile number
                             
                        </Typography>
                        <div style={{textAlign:'center'}}>
                            <OtpInputs
                                onChange={(code) => {
                                    handleOtp(code);
                                }}
                               // ref={otpRef}
                                inputType={'tel'}
                                value={otp}
                                shouldAutoFocus={true}
                                isInputNum={true}
                                numInputs={6}
                                containerStyle={{
                                    flex: 1,
                                    justifyContent:'center'
                                }}
                                inputStyle={"otpInputField"}
                                renderInput={(props) => <input ref={otpRef} {...props} />}
                                />
                        </div>
                    </div>
                    {isValidPolicy && (
                    <div style={{marginTop:'24px',textAlign:'center',marginBottom:'24px'}}>
                    <Button disabled={otp.length !== 6 ? true : false} onClick={()=>{otpVerification()}} variant="contained" color="success" size="medium"  sx={{ width: 200, padding: 1, margin: 2 }}>Next</Button>
                </div>   
                    )}
                    
                    <div style={{textAlign:'center',marginBottom:'32px'}}>
                        {isValidPolicy && (
                            <Button disabled={seconds !== 0} onClick={handleResend} variant="text">
                                {seconds === 0 ? 'Resend otp' : 'Resend otp (' + seconds + 's)'}
                            </Button>
                        )}
                    </div>
             
                </Paper>
            </Container>
        </Home>
    )
}

export default OTP
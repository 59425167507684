import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import colorsv1 from '../config/colorsv1';
import { TextField } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import StatusCheck from '../components/StatusCheck';
import { getUserDetails, validatePAN } from '../actions/policyAction';
import { useDispatch, useSelector } from 'react-redux';
import { canProceed } from '../helper/canProceed';
import { getTitle } from '../helper/formatter';
import { pathOr } from 'ramda';
import { getStepsForNav } from '../helper/steps';


const KYC = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const policy = useSelector(state => state.Auth.policy)
     const [info, setInfo] = useState({
        panNumber: '',
        phoneNumber: '',
        dob: null,
    });
    const updateForm = (val, name) => {
        setInfo({
        ...info,
        [name]: val,
        });
    };
    const [error, setError] = useState({
        panNumber: {
        hasError: true,
        errorMessage: '',
        },
        phoneNumber: {
        hasError: true,
        errorMessage: '',
        },
        dob: {
        hasError: true,
        errorMessage: '',
        },
    });

      const handleAadhaarVerification = async () => {
    const body = {
      panNumber: info.panNumber,
      optFor: policy.optFor,
    };
    const status = await dispatch(
      validatePAN(body),
    );
    if (status) {
      navigate('/upload');
    } 
      };
  
          const handleAuthMe = async() => {
           const status = await dispatch(
          getUserDetails(),
           );
      
    }
      useEffect(() => {
          handleAuthMe();
      }, [dispatch]);
  
    const steps = useSelector(state => state.Auth.steps)
 useEffect(() => {
   const step = getStepsForNav(steps, policy, 'kyc');
   if(step!='')
                            navigate(step)
 }, [dispatch, steps])
  
  
  
  const panRef = useRef(null);
  const mobileRef = useRef(null);
  const dobRef = useRef(null);

    const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.target === panRef.current) {
        mobileRef.current.focus();
      }
      if (event.target === mobileRef.current) {
        dobRef.current.focus();
      }
    }
  };

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
             {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 

                
<PolicyCard/>
                

                    <div className='body mediumFont' style={{marginTop:'24px'}}>{"Complete your KYC"}</div>

                   <StatusCheck/>

                    <div style={{ background: colorsv1.grey[200], padding: '24px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
              <TextField
                    required
                    inputRef={panRef}
                    onKeyDown={handleKeyDown}
                id="panNumber"
                name="panNumber"
                label="Enter PAN number"
                fullWidth
                autoComplete="given-name"
                    variant="standard"
                    inputProps={{
                     maxLength: 10,
        style: {
          textTransform: 'uppercase',
        },
      }}
                error={error.panNumber.hasError}
                helperText={error.panNumber.errorMessage}
                onChange={(event) => {
                      updateForm(event.target.value, 'panNumber');

                      if (!new RegExp('[A-Z]{5}[0-9]{4}[A-Z]{1}').test(event.target.value.toLocaleUpperCase())) {
                        setError({
                          ...error,
                          panNumber: {
                            hasError: true,
                            errorMessage: 'Enter valid PAN number',
                          },
                        })
                      } else {
                       
                        setError({
                          ...error,
                          panNumber: {
                            hasError: false,
                            errorMessage: '',
                          },
                        })
                      }
                    }}
              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
              <TextField
                    required
                    inputRef={mobileRef}
                    onKeyDown={handleKeyDown}
                    id="phoneNumber"
                    inputProps={{
                      maxLength: 10
                    }}
                name="phoneNumber"
                label="Enter Phone number"
                    fullWidth
                   type="number"
                autoComplete="given-name"
                variant="standard"
                error={error.phoneNumber.hasError}
                    helperText={error.phoneNumber.errorMessage}
                onChange={(event) => {
                  updateForm(event.target.value, 'phoneNumber');

                  if (!new RegExp('^[6789][0-9]{9}$').test(event.target.value)) {
                    setError({
                      ...error,
                      phoneNumber: {
                        hasError: true,
                        errorMessage: 'Enter valid phone number',
                      },
                    })
                  } else {
                    
                    setError({
                      ...error,
                      phoneNumber: {
                        hasError: false,
                        errorMessage: '',
                      },
                    })
                  }
                }}
              />
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid item xs={12} sm={12}>
                        <DesktopDatePicker
                      label="DOB"
                      inputRef={dobRef} 
                      fullWidth
                       format="DD/MM/YYYY"
                            inputFormat="DD/MM/yyyy"
                                        value={info.dob} 
                                        error={error.dob.hasError}
                                        helperText={error.dob.errorMessage}
                            onChange={val => {
                              if(val!='Invalid date' && val!=null){
                                updateForm(val, 'dob');
                                setError({
                                  ...error,
                                  dob: {
                                    hasError: false,
                                    errorMessage: ''
                                  }
                                })
                              }else{
                                setError({
                                  ...error,
                                  dob: {
                                    hasError: true,
                                    errorMessage: 'Enter valid date'
                                  }
                                })
                              }
                                
                              }}
                            renderInput={(params) => <TextField {...params} inputVariant="standard" required fullWidth/>}
                            sx={{width: '100%'}} style={{width: '100%'}} slotProps={{ textField: { variant: 'standard',required: true, error:error.dob.hasError } }} 
                        />
                    
                    </Grid>
                    </LocalizationProvider>
                        </Grid>
                    </div>

                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    <Button disabled={canProceed(error)} onClick={()=>{handleAadhaarVerification();}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default KYC
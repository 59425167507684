import { isEmpty, pathOr } from 'ramda'
import { LOGOUT, TOGGLE_LOADER, UPDATE_AUTH_TOKEN } from '../actions/types'
import {store} from '../config/store'
import { Navigate } from 'react-router-dom'
const Api = {
  fetch: async function ({ method, url, body }) {
    store.dispatch({
      type: TOGGLE_LOADER,
      payload: true
    })
    const state = store.getState()
    const accessToken = pathOr('', ['Auth', 'accessToken'], state)
    const policyId = pathOr('', ['Auth', 'policyId'], state)
    const headers = {
      'content-type': 'application/json',
      authorization: accessToken !== '' ? 'Bearer ' + accessToken : accessToken
    }
    const response = await fetch(process.env.REACT_APP_API_URL + url, {
      method,
      headers,
      body
    })
    if (response.ok) {
      store.dispatch({
        type: TOGGLE_LOADER,
        payload: false
      })
      const authorization = response.headers.get('authorization')
      // const authorization = pathOr('', ['authorization'], response.headers.map)
      if (!isEmpty(authorization) && authorization !== null) {
        store.dispatch({
          type: UPDATE_AUTH_TOKEN,
          payload: { accessToken: authorization }
        })
      }
      const res = await response.json()
      return res
    } else {
      store.dispatch({
        type: TOGGLE_LOADER,
        payload: false
      })
      const res = await response.json()
      const error = pathOr('', ['data', 'error'], res)
      if (error === 'jwt expired' || error === 'authorization token required') {
        store.dispatch({
          type: LOGOUT
        })
         Navigate('/'+policyId)
      }
      return res
    }
  },
  get: async function (url) {
    const state = store.getState()
    const accessToken = pathOr('', ['Auth', 'accessToken'], state)
    const headers = {
      'content-type': 'application/json',
      authorization: accessToken !== '' ? 'Bearer ' + accessToken : accessToken
    }
    return await this.fetch({ method: 'get', url, headers })
  },
  post: async function (body, url) {
    return await this.fetch({ method: 'post', url, body: JSON.stringify(body) })
  }
}

export default Api

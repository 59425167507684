import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import colorsv1 from '../config/colorsv1';
import { TextField } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { formatCurrency, getTitle } from '../helper/formatter';
import { getCustomEMIDetails, getUserDetails, selectTenure, submitReqLoanAmount } from '../actions/policyAction';
import { getStepsForNav } from '../helper/steps';


const Tenure = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const policy = useSelector(state => state.Auth.policy)
    const loanInfo = useSelector(state => state.Auth.loanInfo)
    const [type, setType] = useState('3');
    const [sdata, setSdata] = useState([])

    const [defaultLabel, setDefaultlabel] = useState([{
        label1: '6 months',
        emi: pathOr(0, ['loanOnInsuranceEmi', '6', 'emi'], loanInfo),
        totalInterest: pathOr(0, ['loanOnInsuranceEmi', '6', 'totalInterest'], loanInfo),
        totalLoanAmount: pathOr(0, ['loanOnInsuranceEmi', '6', 'totalLoanAmount'], loanInfo),
    }]);
     const getCreditAmount = () => {
        switch (pathOr('', ['optFor'], policy)) {
            case 'premiumFinance':
                return pathOr(0,['eligiblePremiumFinance'],policy);
            case 'assignment':
                return pathOr(0,['surrenderValue'],loanInfo);
            default:
                return pathOr(0,['eligibleLoanAmount'],policy);
        }
    }
    const steps = useSelector(state => state.Auth.steps)

  const getEMI = async(val) => {
           const status = await dispatch(
          getCustomEMIDetails({requiredLoanAmount:parseInt(val)}),
           );
      
    }

        const handleAuthMe = async() => {
           const status = await dispatch(
          getUserDetails(),
           );
      
    }
      useEffect(() => {
          handleAuthMe();
      }, [dispatch]);
  

 useEffect(() => {
   const step = getStepsForNav(steps, policy, 'tenure');
   if(step!='')
                            navigate(step)
     }, [dispatch,steps])

   
    const getInterestRate = () => {
        switch (pathOr('', ['optFor'], policy)) {
            case 'premiumFinance':
                return pathOr(0,['interest'],loanInfo);
            case 'assignment':
                return pathOr(0,['surrenderValue'],loanInfo);
            default:
                return pathOr(0,['loanInterest'],loanInfo);
        }
    }
    const getAmount = () => {
        switch (pathOr('', ['optFor'], policy)) {
            case 'premiumFinance':
                return pathOr(0,['premium'],policy);
            case 'assignment':
                return pathOr(0,['surrenderValue'],loanInfo);
            default:
                return pathOr(0,['eligibleLoanAmount'],loanInfo);
        }
    }

    
    const setSliderData = () => {
        
      let sliderData = Object.keys(pathOr([], ['loanOnInsuranceEmi'], loanInfo)).map(data => {
        let adata = []
          const emiInfo = loanInfo.loanOnInsuranceEmi[data]
        //adata.push({ value: emiInfo.sliderValue, label1: data + ' months', label2: data, ...emiInfo })
        return { value: emiInfo.sliderValue, label1: data + ' months', label2: data, ...emiInfo }
      })
        setSdata(sliderData)
}


    const valuetext = (value) => {
        return `${value}°C`;
    }

    const valueLabelFormat = (value) => {
        if (sdata.length == 0) {
            return '6 months'
        }
        //setDefaultlabel(marks.filter(v => { return (v.value == value) }).map(a => { return a.label1 }))
        return sdata.filter(v => { return (v.value == value) }).map(a => { return a.label1 });
    }
    const onvalueChange = (a, val) => {
        debugger;
        const data = sdata.filter(v => { return (v.value == val) })
        setDefaultlabel(data)
        updateForm(data[0].label2, 'tenure')
    }

    const handleTenure = async () => {
        
        if (pathOr('', ['optFor'], policy) == 'loan') {
            const body = {
                policyId: policy._id,
                tenure: parseInt(loanInfodetails.tenure),
                requiredLoanAmount:parseInt(loanInfodetails.loanAmount)
            };
            const status = await dispatch(
            submitReqLoanAmount(body),
            );
            if (status) { 
                navigate('/esign')
            }
        } else {
            
            const body = {
            policyId: policy._id,
            tenure: type,
            };
            
            const status = await dispatch(
            selectTenure(body),
            );
            
            if (status) {
                  
                if (pathOr(false, ['isUpfrontEligible'], steps))
                    navigate('/upfront');    
                else
                    navigate('/esign')
            } 
        }
    };
    
    
         const loanInitialState = {
    loanAmount: getCreditAmount(),
    tenure: 6,
         };
     const errorInitialState = {
    loanAmount: {
      hasError: false,
      errorMessage: '',
    },
    tenure: {
      hasError: false,
      errorMessage: '',
    },
     };
      const [loanInfodetails, setLoanInfo] = useState(loanInitialState);
  const [error, setError] = useState(errorInitialState);
        const updateForm = (val, name) => {
    setLoanInfo({
      ...loanInfodetails,
      [name]: val,
    });
    };
        useEffect(() => {
            setSliderData();
        }, [dispatch, loanInfo]);
    


    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h2' style={{ color: colorsv1.primary.dark[900], marginBottom: '20px' }}>{"Congratulations !! "}</div>
                    <div className='h4' style={{ marginBottom: '16px' }}>{"Your policy is eligible for "}{ getTitle(pathOr('', ['optFor'], policy)).toLowerCase()}</div>
                    <div className='body' style={{ color: colorsv1.grey[700], marginBottom: '24px' }}>{"Select "}{ getTitle(pathOr('', ['optFor'], policy)).toLowerCase()}{" tenure from below"}</div>
                    
                    <div style={{ border: '1px solid #73b462', padding: '16px 12px', display:'flex',alignItems:'center', justifyContent: 'space-between',borderRadius:'12px',marginBottom:'16px' }}>
                        <div>
                            <div className='h4'>{"Credit amount"}</div>
                            <div className='h1' style={{ color: colorsv1.primary.dark[800] }}>{ formatCurrency(getCreditAmount())}</div>
                        </div>
                    </div>
                
                    <PolicyCard />
                    
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',marginTop:'24px' }}>
                        <div className='h3'>{"Choose EMI  Plan"}</div>
                        <div className='body' style={{ color: colorsv1.grey[700] }}>{"Effective interest cost @"}{ getInterestRate()}{"%"}</div>
                    </div>
                    <div style={{ border: '0.5px solid #ABABAB' }}></div>
                    
                    {pathOr('', ['optFor'], policy) == 'loan' && (
                        <div style={{marginTop:'24px',background:colorsv1.grey[300],padding:'24px', borderRadius:'6px'}}>
                        <TextField
                required
                id="loanAmount"
                name="loanAmount"
                label="Enter loan Amount"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                    value={loanInfodetails.loanAmount}
                    error={error.loanAmount.hasError}
                    helperText={error.loanAmount.errorMessage}
                onChange={(evt) => {
                  updateForm(evt.target.value, evt.target.name)
                  if (!new RegExp('^[0-9]{4,}$').test(evt.target.value)) { 
                    setError({
                      ...error,
                      loanAmount: {
                        hasError: true,
                        errorMessage: '',
                      },
                    })
                  } else {
                     setError({
                      ...error,
                      loanAmount: {
                        hasError: false,
                        errorMessage: '',
                      },
                     })
                      getEMI(evt.target.value)
                  }
                }}
//                 onBlur={evt => {
//                   fetchBank(evt.target.value);
//                 }}
              />
                            <div style={{ marginTop: '54px' }}>
                            <Slider
                            aria-label="Restricted values"
                            defaultValue={6}
                            valueLabelFormat={valueLabelFormat}
                            onChange={onvalueChange }
                            getAriaValueText={valuetext}
                            step={null}
                            valueLabelDisplay="on"
                            marks={sdata}
                                />
                                </div>
                        <div className='rowClass justifyBetween'>
                        <div>{pathOr('',['0','label1'],defaultLabel) }</div>
                            <div>{pathOr('',['0','emi'],defaultLabel)}</div>
                            </div>
</div>
                    )}
                    {pathOr('', ['optFor'], policy) == 'premiumFinance' && (
<div style={{ background: colorsv1.grey[300], padding: '12px', display: 'flex', justifyContent: 'space-around',marginTop:'21px',borderRadius:'6px' }}>
                            {Object.keys(loanInfo.emi).map(data => {
                                const emiInfo = loanInfo.emi[data]
                                return (
                                <div onClick={() => { setType(data) }} className={'cursor selectTenure ' + (type == data ? 'activeTab' : 'inactiveTab1')} style={{ borderRadius: '7px' }}>
                            <div className={'body '+(type==data?'activeText':'inactiveText')}>{`${data} Months`}</div>
                            <div className={'h3 '+(type==data?'activeText':'inactiveText')} style={{marginTop:'5px'}}>{formatCurrency(emiInfo.emi)}</div>
                        </div>
                            )
                        })}
                            {/* <div onClick={() => { setType('3') }} className={'cursor selectTenure ' + (type == '3' ? 'activeTab' : 'inactiveTab1')} style={{ borderRadius: '7px' }}>
                            <div className={'body '+(type=='3'?'activeText':'inactiveText')}>{"3 Months"}</div>
                            <div className={'h3 '+(type=='3'?'activeText':'inactiveText')} style={{marginTop:'5px'}}>{"₹ 35,841"}</div>
                        </div>
                        <div onClick={()=>{setType('6')}} className={'cursor selectTenure '+(type=='6'?'activeTab':'inactiveTab1')} style={{ borderRadius:'7px' }}>
                            <div className={'body '+(type=='6'?'activeText':'inactiveText')}>{"6 Months"}</div>
                            <div className={'h3 '+(type=='6'?'activeText':'inactiveText')} style={{marginTop:'5px'}}>{"₹ 18,276"}</div>
                        </div>
                        <div onClick={()=>{setType('9')}} className={'cursor selectTenure '+(type=='9'?'activeTab':'inactiveTab1')} style={{ borderRadius:'7px' }}>
                            <div className={'body '+(type=='9'?'activeText':'inactiveText')}>{"9 Months"}</div>
                            <div className={'h3 '+(type=='9'?'activeText':'inactiveText')} style={{marginTop:'5px'}}>{"₹ 12,424"}</div>
                        </div> */}
                    </div>
                    )}

                    
                    {pathOr('', ['optFor'], policy) == 'premiumFinance' && (
                        <div>
                            <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',marginTop:'24px' }}>
                        <div className='h3'>{"Loan details"}</div>
                    </div>
                    <div style={{ border: '0.5px solid #ABABAB' }}></div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',marginTop:'24px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"Policy Premium Amount"}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{formatCurrency(pathOr(0,['premium'],policy))}</div>
                    </div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"Credit Amount"}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{ formatCurrency(getCreditAmount())}</div>
                    </div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"Processing Fee"}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{formatCurrency(pathOr(0,['premiumFinanceProcessingFee'],loanInfo))}</div>
                    </div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"GST (18%) "}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{formatCurrency(pathOr(0,['premiumFinanceGst'],loanInfo))}</div>
                    </div>
                    <div style={{ border: '0.5px solid #ABABAB' }}></div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',background:colorsv1.grey[300],marginTop:'12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"Total Financing Amount"}</div>
                        <div className='h3 semiBoldFont' style={{color:colorsv1.grey[800]}}>{formatCurrency(getCreditAmount()+pathOr(0,['premiumFinanceProcessingFee'],loanInfo)+pathOr(0,['premiumFinanceGst'],loanInfo))}</div>
                    </div>
</div>
                    )}
                    {pathOr('', ['optFor'], policy) == 'loan' && (
                        <div>
                            <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',marginTop:'24px' }}>
                        <div className='h3'>{"Loan details"}</div>
                    </div>
                    <div style={{ border: '0.5px solid #ABABAB' }}></div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"Credit Amount"}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{ formatCurrency(pathOr(0,['requiredLoanAmount'],loanInfo))}</div>
                    </div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"Processing Fee"}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{formatCurrency(pathOr(0,['loanProcessingFee'],loanInfo))}</div>
                    </div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[700]}}>{"GST (18%) "}</div>
                        <div className='h3' style={{color:colorsv1.grey[700]}}>{formatCurrency(pathOr(0,['loanGst'],loanInfo))}</div>
                    </div>
                    <div style={{ border: '0.5px solid #ABABAB' }}></div>
                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',background:colorsv1.grey[300],marginTop:'12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"Total Loan Amount"}</div>
                        <div className='h3 semiBoldFont' style={{color:colorsv1.grey[800]}}>{formatCurrency(pathOr(0,['requiredLoanAmount'],loanInfo)+pathOr(0,['loanProcessingFee'],loanInfo)+pathOr(0,['loanGst'],loanInfo))}</div>
                    </div>
</div>
                    )}
                    
                    {pathOr('', ['optFor'], policy) == 'premiumFinance' && (
<div style={{background:colorsv1.grey[300],borderRadius:'12px',marginTop:'54px'}}>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',marginTop:'12px' }}>
                            <div className='h4' style={{color:colorsv1.grey[800]}}>{"EMI Tenure"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{ type+" months"}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB' }}></div>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"EMI Amount"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{formatCurrency(loanInfo.emi[type].emi)}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB' }}></div>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"Total  Interest Payable"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{formatCurrency(loanInfo.emi[type].totalInterest)}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB' }}></div>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"Total  Loan Repayment"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{formatCurrency(loanInfo.emi[type].totalLoanAmount)}</div>
                        </div>
                    </div>
                    )}
                    {pathOr('', ['optFor'], policy) == 'loan' && (
                        <div style={{background:colorsv1.grey[300],borderRadius:'12px',marginTop:'54px'}}>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px',marginTop:'12px' }}>
                            <div className='h4' style={{color:colorsv1.grey[800]}}>{"EMI Tenure"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{ pathOr('',['0','label1'],defaultLabel)}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB' }}></div>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"EMI Amount"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{formatCurrency(pathOr(0, ['loanOnInsuranceEmi', loanInfodetails.tenure, 'emi'], loanInfo))}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB' }}></div>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"Total  Interest Payable"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{formatCurrency(pathOr(0, ['loanOnInsuranceEmi', loanInfodetails.tenure, 'totalInterest'], loanInfo))}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB' }}></div>
                        <div style={{ display:'flex',alignItems:'center', justifyContent: 'space-between', padding: '12px' }}>
                        <div className='h4' style={{color:colorsv1.grey[800]}}>{"Total  Loan Repayment"}</div>
                            <div className='h3' style={{color:colorsv1.grey[800]}}>{formatCurrency(pathOr(0, ['loanOnInsuranceEmi', loanInfodetails.tenure, 'totalLoanAmount'], loanInfo))}</div>
                        </div>
                    </div>
                    )}
                    

                    {pathOr('', ['optFor'], policy) == 'premiumFinance' && pathOr(0, ['upfront'], loanInfo)>0 && (
                        <div style={{ background: colorsv1.primary.light[100], borderRadius: '12px', marginTop: '54px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', marginTop: '12px' }}>
                                <div className='h3' style={{ color: colorsv1.grey[800] }}>{"Upfront details "}</div>
                            </div>
                            <div style={{ border: '0.5px solid #ABABAB' }}></div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px' }}>
                                <div className='h4' style={{ color: colorsv1.grey[800] }}>{"Upfront to be paid"}</div>
                                <div className='h3 semiBoldFont' style={{ color: colorsv1.grey[800] }}>{formatCurrency(pathOr(0, ['upfront'], loanInfo))}</div>
                            </div>
                       
                        </div>
                    )}
                   

                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                        <Button onClick={() => { handleTenure(); }} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>{(pathOr('', ['optFor'], policy) == 'premiumFinance' && pathOr(0, ['upfront'], loanInfo)>0)?'Make Payment':'Next'}</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default Tenure
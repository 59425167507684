import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import colorsv1 from '../config/colorsv1';
import '../pages/index.css'
import { Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { formatCurrency, getTitle } from '../helper/formatter';

const PolicyCardStatus = (props) => {
    const {
        status,
        type
    } = props;
     const handleClose = (value) => {
    setOpen(false);
  };
    
    const [open, setOpen] = React.useState(false);
    const policy = useSelector(state => state.Auth.policy)
    const tenure = useSelector(state => state.Auth.tenure)
    const loanInfo = useSelector(state => state.Auth.loanInfo)
    const bank=pathOr('',['bank'],policy)

    return (
        <div>
            <div style={{border:'0.5px solid #E0E0E0',borderRadius:'6px'}}>
                        <div style={{ display: 'flex', padding: '16px 16px 10px 16px',justifyContent:'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div className='cardLogo' style={{marginRight:'24px'}}>
                                <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Logos/Max%20Life%20Insurance.png'
                                    alt={"logo"} 
                                    loading="lazy" style={{width:'84px',height:'56px',alignItems:'center'}}
                                ></img>
                            </div>
                            <div>
                                <Typography>{pathOr('',['policyHolderName'],policy)}</Typography>
                                <Typography>{pathOr('',['policyNumber'],policy)}</Typography>
                            </div>
                            </div>
                            
                            <div style={{textAlign:'right'}}>
                                <div style={{textAlign:'center'}}>
                            <div className='semiBoldFont ten' style={{ color: colorsv1.primary.dark[600], background: colorsv1.primary.light[100], paddingLeft: '6px', paddingRight: '6px', paddingTop: '1px', paddingBottom: '1px', borderRadius: '4px' }}>{ getTitle(pathOr('', ['optFor'], policy))}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ borderTop: '1px solid #E0E0E0' }}></div>
                        <div style={{ padding: '10px 16px 16px 16px' }}>
                        <div className='body' style={{color:colorsv1.grey[600]}}>{"Plan name"}</div>
                        <div className='body' style={{marginTop:'7px'}}>{pathOr('',['planName'],policy)}</div>
                        </div>
                <div style={{ borderTop: '1px solid #E0E0E0' }}></div>
                {type != 'assignment' && (
                    <div className={'rowClassDynamic '+(status=='rejected'?'statusRejectBg':'statusApprovedBg')} style={{ padding: '16px'}}>
                            <div className='rowClass justifyBetween'>
                            <div>{"Loan amount - "}{ formatCurrency(pathOr(0,[pathOr('', ['optFor'], policy),'loanAmount'],policy))}</div>
                            </div>
                            <div className='rowClass'>
                                <img className='hidephone' style={{width:'24px',height:'24px',marginRight:'8px'}} src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/tenure.png'/>
                            <div>{"Tenure  - "}{ pathOr(0,[pathOr('', ['optFor'], policy),'tenure'],policy)}{" Months"}</div>
                            </div>
                        </div>
                )}
                {type == 'assignment' && status == 'pending' && (
                    <div className={'rowClass '} style={{ padding: '16px', background: colorsv1.grey[300], borderRadius: '0 0 6px 6px' }}>
                        <div className='h4' style={{color:colorsv1.primaryColor  }}>{"Surrender Value "}</div>
                        <div className='h4' style={{color:colorsv1.primaryColor ,marginLeft:'12px' }}>{ formatCurrency(pathOr(0,['surrenderValue'],policy))}</div>
 </div>
                )}
                {type == 'assignment' && 'isVideoKyc'=='' && status=='approved' && (
                    <div className={'rowClass '} style={{ padding: '16px',background:colorsv1.grey[300],borderRadius:'0 0 6px 6px'}}>
<div onClick={() => {  }} style={{ width: '32px', height: '32px', borderRadius: '16px', background: colorsv1.primary.light[200], display: "flex", cursor: 'pointer' }}>
                                                <CameraAltOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                        </div>
                        <div className='h4' style={{marginLeft:'16px'}}>{"Video KYC 15-10-2023"}</div>
                    </div>
                )}
                {type == 'assignment' && 'isVideoKyc' == '' && status=='approved' && (
<div className={'rowClass '} style={{ padding: '16px',background:colorsv1.primaryColor,borderRadius:'0 0 6px 6px'}}>
<div onClick={() => {  }} style={{ width: '32px', height: '32px', borderRadius: '16px', background: colorsv1.primary.light[200], display: "flex", cursor: 'pointer' }}>
                                                <CameraAltOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                        </div>
                        <div className='h4' style={{ marginLeft: '16px', color: colorsv1.white }}>{"complete your video KYC "}</div>
                        <img style={{height:'32px',width:'82px',marginLeft:'16px'}} src="https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/arrow-right-white.png"/>
                    </div>
                )}
                        
                        
            </div>
            {status=='rejected' && type != 'assignment' && pathOr('',[pathOr('', ['optFor'], policy),'upfront'],policy)>0 && (
                <div className='rowClassDynamic' style={{ padding: '16px', background: colorsv1.grey[200], marginTop: '16px' }}>
                    <div className='h4'>{"Upfront payment is refunded "} { formatCurrency(pathOr(0,['upfront'],loanInfo))}</div>
                    <div onClick={() => { setOpen(true) }} className='subTitles mediumFont cursor' style={{ padding: '8px', border: '1px solid #ABABAB', width: '80px' }}>{"Check Status"}</div>
                </div>
            )}
            {type == 'assignment' && status=='approved' && 'isVideoKyc' == 'isVideoKyc' && (
                <div style={{marginTop:'14px'}} className='rowClass'>
                    <InfoOutlinedIcon style={{ color: colorsv1.notification.dark.error }} />
                    <div className='h4' style={{marginLeft:'12px'}}>{"To continue your life cover "}</div>
                </div>
            )}

            <Dialog onClose={handleClose} fullWidth={true} open={open}>
                <DialogTitle style={{ marginTop: '12px' }}>Refund details</DialogTitle>
        <DialogContent dividers>
                    <div className='rowClass justifyBetween' style={{marginBottom:'20px'}}>
                        <div className='body'>{"Transaction id"}</div>
                        <div className='body'>{"544645989568995"}</div>
                    </div>
                    <div className='rowClass justifyBetween' style={{marginBottom:'20px'}}>
                        <div className='body'>{"Date"}</div>
                        <div className='body'>{"12/03/2023"}</div>
                    </div>
                    <div className='rowClass justifyBetween' style={{marginBottom:'20px'}}>
                        <div className='body'>{"Amount"}</div>
                        <div className='body'>{"₹ 34,672"}</div>
                    </div>
                    <div className='rowClass justifyBetween' style={{marginBottom:'20px'}}>
                        <div className='body'>{"Bank Account Number"}</div>
                        <div className='body'>{pathOr('',['accountNumber'],bank)}</div>
                    </div>
                     <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>

        </div>
    )
}

export default PolicyCardStatus
import { pathOr } from "ramda"

export const getSteps = (val,pol) => {
    if (!pathOr(false, ['isPanVerified'], val)) {
        return '/kyc'
    } else if(!pathOr(false, ['isAadhaarUploaded'], val) || !pathOr(false, ['isSelfieUploaded'], val)){
        return '/upload'
    } else if (!pathOr(false, ['isBankVerified'], val)) {
        return '/bank'
    } else if (!pathOr(false, ['isUpfrontEligible'], val) && pathOr('',[pathOr('',['optFor'],pol),'esignUrl'],pol)=='') {
        if(pathOr('',['optFor'],pol)!='assignment')
            return '/tenure'
        else
            return '/assignment'
    } else if (pathOr(false, ['isUpfrontEligible'], val) && !pathOr(false, ['isUpfrontPaid'], val) && pathOr('',[pathOr('',['optFor'],pol),'esignUrl'],pol)=='') {
        if(pathOr('',['optFor'],pol)!='assignment')
            return '/tenure'
        else
            return '/assignment'
    }
    else if (!pathOr(false, ['isEsignCompleted'], val) ) {
        return '/esign'
    }  else if (pathOr('',['optFor'],pol)!='assignment' && !pathOr(false, ['isEnachCompleted'], val) ) {
        return '/enach'
    } 
    else {
        return '/status'
    }
    
}

export const getScreen = (val) => {
    const screen = ['intro', 'kyc', 'upload', 'bank', 'timer', 'tenure', 'upfront', 'upfrontsuccess', 'esign', 'enach', 'enachFailure', 'enachSuccess', 'pfDetails', 'status', 'assignedDetails', 'assignment', 'esignurl', 'assignmentsuccess']
    return val.includes(screen)
}

export const getStepsForNav = (val, pol, step) => {
    if (!pathOr(false, ['isPanVerified'], val)) {
        if (step == 'kyc')
            return ''
        else
            return '/kyc'
    } else if (!pathOr(false, ['isAadhaarUploaded'], val) || !pathOr(false, ['isSelfieUploaded'], val)) {
        if (step == 'upload')
            return ''
        else
            return '/upload'
    } else if (!pathOr(false, ['isBankVerified'], val)) {
        if (step == 'bank')
            return ''
        else
            return '/bank'
    } else if (!pathOr(false, ['isUpfrontEligible'], val) && pathOr('', [pathOr('', ['optFor'], pol), 'esignUrl'], pol) == '') {
        if (step == 'tenure') {
            if(pathOr('',['optFor'],pol)!='assignment')
                return ''
            else
            return '/assignment'
            
        }
        else {
            if(pathOr('',['optFor'],pol)!='assignment')
            return '/tenure'
        else
            return '/assignment'
        }
    } else if (pathOr(false, ['isUpfrontEligible'], val) && !pathOr(false, ['isUpfrontPaid'], val) && pathOr('',[pathOr('',['optFor'],pol),'esignUrl'],pol)=='') {
        if (step == 'tenure') {
            if(pathOr('',['optFor'],pol)!='assignment')
                return ''
            else
            return '/assignment'
        }
        else {
            if(pathOr('',['optFor'],pol)!='assignment')
            return '/tenure'
        else
            return '/assignment'
        }
    }
    else if (!pathOr(false, ['isEsignCompleted'], val)) {
        if (step == 'esign')
            return ''
        else
        return '/esign'
    } else if  (pathOr('',['optFor'],pol)!='assignment' &&!pathOr(false, ['isEnachCompleted'], val)) {
        if (step == 'esign')
            return ''
        else
        return '/enach'
    } else {
        if (step == 'status')
            return ''
        else
        return '/status'
    }
    
}
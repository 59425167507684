import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { getTitle } from '../helper/formatter';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';


const NotFound = (props) => {
    const navigate = useNavigate()
    const policy = useSelector(state => state.Auth.policy)
    

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {/* {getTitle(pathOr('', ['optFor'], policy))} */}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div style={{ textAlign: 'center' }}>
                        <img
                        style={{width:'350px',height:'250px'}}
                            src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/404.png' />
                      
                    </div>



                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    {/* <Button onClick={()=>{navigate('/status');}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Home</Button> */}
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default NotFound
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import colorsv1 from '../config/colorsv1';
import '../pages/index.css'
import { Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

const PolicyCard = (props) => {
    const policy = useSelector(state => state.Auth.policy)
    return (
        <div style={{background:colorsv1.primary.light[100],borderRadius:'6px'}}>
                        <div style={{ display: 'flex', padding: '16px 16px 10px 16px',justifyContent:'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div className='cardLogo' style={{marginRight:'24px'}}>
                                <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Logos/Max%20Life%20Insurance.png'
                                    alt={"logo"} 
                                    loading="lazy" style={{width:'84px',height:'56px',alignItems:'center'}}
                                ></img>
                            </div>
                            <div>
                                <Typography>{pathOr('',['policyHolderName'],policy)}</Typography>
                                <Typography>{pathOr('',['policyNumber'],policy)}</Typography>
                            </div>
                            </div>
                            
                            <div style={{textAlign:'right'}}>
                                <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Policylifev2.png'
                                    alt={'logo'} loading='lazy' style={{ width: '24px', height: '24px' }}></img>
                                <div className='InsurType'>
                        <div className='semiBoldFont subTitles' style={{ background: '#fff', paddingLeft: '6px', paddingRight: '6px', paddingTop: '1px', paddingBottom: '1px', borderRadius: '4px' }}>{ pathOr('',['savingType'],policy)}</div>
                                    <div className='mediumFont subTitles' style={{color:colorsv1.primaryColor,marginLeft:'10px'}}>Savings</div>
                                </div>
                            </div>
                        </div>
            <div style={{ borderTop: '1px solid #fff' }}></div>
            <div style={{padding:'10px 16px 16px 16px'}}>
                        <div className='body' style={{color:colorsv1.grey[600]}}>{"Plan name"}</div>
                        <div className='body' style={{marginTop:'7px'}}>{ pathOr('',['planName'],policy)}</div>
</div>
                    </div>
        
    )
}

export default PolicyCard
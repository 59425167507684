import React, { useEffect, useRef, useState } from 'react'
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import colorsv1 from '../config/colorsv1';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PolicyCard from '../components/PolicyCard';
import StatusCheck from '../components/StatusCheck';
import UploadIcon from '@mui/icons-material/Upload';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Webcam from 'react-webcam';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, validateDocument, validateSelfie } from '../actions/policyAction';
import { canProceed } from '../helper/canProceed';
import { pathOr } from 'ramda';
import { getTitle } from '../helper/formatter';
import { getStepsForNav } from '../helper/steps';
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";



const DocumentUpload = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const policy = useSelector(state => state.Auth.policy)
    const fileUploadRef = useRef(null);
    const [imgAadhaarLogo, setImgAadhaarLogo] = React.useState(true);
    const [imgSelfyLogo, setImgSelfyLogo] = React.useState(true);
    const [imgAadharSrc, setAadharImgSrc] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [imgPANSrc, setPANImgSrc] = React.useState(null);
    const [imgSelfieSrc, setSelfieImgSrc] = React.useState(null);
    const [openCamera, setOpenCamera] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
      const handleClickOpen = (val) => {
        setType(val)
        setOpen(true);
      };
      const Input = styled('input')({
    display: 'none',
      });
    const initialState = {
    aadhar: '',
    selfie: '',
  };
    const errorInitialState = {
    aadhar: {
      hasError: true,
      errorMessage: '',
    },
    selfie: {
      hasError: true,
      errorMessage: '',
    },
    };
    const [journeyInfo, setJourneyInfo] = useState(initialState);
  const [error, setError] = useState(errorInitialState);

      const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

        const handleAuthMe = async() => {
           const status = await dispatch(
          getUserDetails(),
           );
      
    }
      useEffect(() => {
          handleAuthMe();
      }, [dispatch]);

    const steps = useSelector(state => state.Auth.steps)
 useEffect(() => {
   const step = getStepsForNav(steps, policy, 'upload');
   if(step!='')
                            navigate(step)
     }, [dispatch,steps])

    const webcamRef = React.useRef(null);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const handleSwitch = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: facingMode
      };
    
      const handleListItemClick = (value) => {
    setOpen(false);
    setSelectedValue(value);
    if (value === "Choose File") {
      fileUploadRef.current?.click();
    } else {
      setOpenCamera(true)
    }
      };
    
  const handleChange = evt => {
    debugger;
    const reader = new FileReader()
    const file = evt.target.files[0]
    reader.onload = async function (upload) {
      console.log(upload)
      //setSelectedFileName(file.name)
      //console.log('result', upload.target.result)
      setOpen(false)
      const body = {
        image: upload.target.result.split(',')[1],
        type: type === 'pan' ? 'PAN' : 'AADHAAR',
        policyId: policy._id,
      };
      if (type === 'pan') {
        setPANImgSrc(upload.target.result)
        validateImage(body)
      }
      if (type === 'aadhar') {
        setAadharImgSrc(upload.target.result)
        validateImage(body)
        setImgAadhaarLogo(false);
      }
      if (type === 'selfie') {
        setImgSelfyLogo(false);
        const status = await dispatch(validateSelfie({ photo: upload.target.result, policyId: policy._id }))
        if (status) {
          setError({
            ...error,
            selfie: {
              hasError: false,
              errorMessage: ''
            }
          })
        } else {
          setError({
            ...error,
            selfie: {
              hasError: true,
              errorMessage: 'No face detected'
            }
          })
        }
      }
     
    }
    console.log(reader.readAsDataURL(file))
  }
    
      const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setOpen(false)
    const body = {
      image: imageSrc.split(',')[1],
      type: type === 'pan' ? '' : 'AADHAAR',
      policyId: policy._id,
    };
    setTimeout(async () => {
      if (type === 'pan') {
        setPANImgSrc(imageSrc)
        validateImage(body)
      }
      if (type === 'aadhar') {
        setAadharImgSrc(imageSrc)
        validateImage(body)
        setImgAadhaarLogo(false);
      }
      if (type === 'selfie') {
        setSelfieImgSrc(imageSrc)
        setImgSelfyLogo(false);
        const status = await dispatch(validateSelfie({ photo: imageSrc.split(',')[1], policyId: policy._id }))
        if (status) {
          setError({
            ...error,
            selfie: {
              hasError: false,
              errorMessage: ''
            }
          })
        } else {
          setError({
            ...error,
            selfie: {
              hasError: true,
              errorMessage: 'No face detected'
            }
          })
        }
      }
      setOpenCamera(false)
    }, 500);


      };
  
    const validateImage = async body => {
    //setIsVisible(false);
    let status;
    try {
      //if (documentType === 'pan' || documentType === 'aadhar') {
      status = await dispatch(validateDocument(body));
      if (status) {
        setJourneyInfo({
          ...journeyInfo,
          [type]: body.image,
        });
        setError({
          ...error,
          [type]: {
            hasError: false,
            errorMessage: '',
          },
        });
      } else {
        setJourneyInfo({
          ...journeyInfo,
          [type]: '',
        });
        setError({
          ...error,
          [type]: {
            hasError: true,
            errorMessage: '',
          },
        });
      }
      //}
      
    } catch (e) {
      setError({
        ...error,
        [type]: {
          hasError: true,
          errorMessage: e.message,
        },
      });
    }
  };
// alert(windowDimensions.width)
    return (
       <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                {!openCamera && (
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 

                
                        <PolicyCard />
                

                        <div className='body mediumFont' style={{ marginTop: '24px' }}>{"Complete your KYC"}</div>

                        <StatusCheck step={2} />

                        <div style={{ background: colorsv1.primary.light[100], padding: '24px', border: '1px dashed #ABABAB' }}>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className='uploadDiv'>
                    {imgAadhaarLogo && (
 <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/uploadaddahar.png'
                                        alt={"logo"}
                                        loading="lazy" style={{ width: '40px', height: '36px', alignItems: 'flex-start', marginRight: '20px' }}
                                    ></img>
                    )}                
                   
                                    <Typography className='regular' variant='subtitle2' style={{}}>
                                        Aadhaar
                                    </Typography>
                                </div>
                                <div style={{ justifyContent: 'flex-end' }}>
                                    {!imgAadharSrc && (
                                        <div className='cursor'>
                                            <div onClick={() => {handleClickOpen('aadhar') }} style={{ width: '32px', height: '32px', borderRadius: '16px', background: colorsv1.primary.light[200], display: "flex", cursor: 'pointer' }}>
                                                <UploadIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                                            </div>
                                            <div onClick={() => {handleClickOpen('aadhar') }} className='subTitles'>upload</div>
                                        </div>
                                    )}
                                    {imgAadharSrc && (
                                        <div className='uploadDiv'>
                                            <img style={{ width: '100px', height: '60px' }} src={imgAadharSrc} />
                                            <div style={{ marginLeft:'10px' }}>
                                            <div onClick={() => { handleClickOpen('aadhar') }} style={{ width: '32px', height: '32px', borderRadius: '16px', background: colorsv1.primary.light[200], display: "flex", cursor: 'pointer' }}>
                                                <UploadIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                                            </div>
                                                <div className='subTitles'>Re-upload</div>
                                                </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ background: colorsv1.primary.light[100], padding: '24px', border: '1px dashed #ABABAB', marginTop: '32px' }}>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                  <div className='uploadDiv'>
                    {imgSelfyLogo && (
 <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/uploadselfie.png'
                                        alt={"logo"}
                                        loading="lazy" style={{ width: '40px', height: '36px', alignItems: 'flex-start', marginRight: '20px' }}
                                    ></img>
                    )}
                                   
                                    <Typography className='regular' variant='subtitle2' style={{}}>
                                        Take a selfie
                                    </Typography>
                                </div>
                                <div style={{ justifyContent: 'flex-end' }}>
                                    {!imgSelfieSrc && (
                                        <>
                                            <div onClick={() => { setType('selfie'); setOpenCamera(true) }} style={{ width: '32px', height: '32px', borderRadius: '16px', background: colorsv1.primary.light[200], display: "flex", cursor: 'pointer' }}>
                                                <CameraAltOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                                            </div>
                                            <div className='subTitles'>selfie</div>
                                        </>
                                    )}
                                    {imgSelfieSrc && (
                                        <div className='uploadDiv'>
                                            <img style={{ width: '100px', height: '60px' }} src={imgSelfieSrc} />
                                            <div style={{ marginLeft:'10px' }}>
                                            <div onClick={() => { setType('selfie'); setOpenCamera(true) }} style={{ width: '32px', height: '32px', borderRadius: '16px', background: colorsv1.primary.light[200], display: "flex", cursor: 'pointer' }}>
                                                <CameraAltOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                                            </div>
                                                <div className='subTitles'>Retake </div>
                                                </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '24px', textAlign: 'center', marginBottom: '32px' }}>
                            <Button disabled={canProceed(error)} onClick={() => { navigate('/bank'); }} variant="contained" color="success" size="medium" sx={{ width: 250, padding: 1, margin: 2 }}>Next</Button>
                        </div>

                    </Paper>
                )}
            </Container>

<Dialog onClose={handleClose} fullWidth={true} open={open}>
        <DialogTitle style={{ marginTop: '12px' }}>Upload Documents</DialogTitle>
        <Input accept='image/*' ref={fileUploadRef} id="contained-button-file" name='contained-button-file' type="file" onChange={(evt) => { handleChange(evt) }} />
        <List sx={{ pb: 5 }}>


          <ListItem button onClick={() => { fileUploadRef.current?.click(); }}>
            <ListItemAvatar>
              <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Flile.png'
                alt={"logo"}
                loading="lazy" style={{ width: '32px', height: '32px', alignItems: 'flex-start' }}
              ></img>
            </ListItemAvatar>
            <ListItemText primary="Choose File" />
          </ListItem>


          <ListItem button onClick={() => handleListItemClick('Take Photo')}>
            <ListItemAvatar>
              <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/cameramenu.png'
                alt={"logo"}
                loading="lazy" style={{ width: '32px', height: '32px', alignItems: 'flex-start' }}
              ></img>
            </ListItemAvatar>
            <ListItemText primary="Take Photo" />
          </ListItem>
        </List>
      </Dialog>

             {openCamera && (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <div className='camDiv'>
            <Webcam
              facingMode
            audio={false}
            ref={webcamRef}
            screenshotQuality={0.5}
            screenshotFormat="image/jpeg"
            width={windowDimensions.width < 415 ? 300 : 550}
            videoConstraints={videoConstraints}
              ></Webcam>
              <div>
          <div style={{ marginTop: '24px', textAlign: 'center'}}>
            <Button onClick={() => capture()} variant="contained" color="success" size="medium" sx={{ width: 200, padding: 1, margin: 2 }}>Click photo</Button>
          </div>
          <div style={{ marginTop: '24px', textAlign: 'center'}}>
            <Button onClick={() => handleSwitch()} variant="contained" color="success" size="medium" sx={{ width: 200, padding: 1, margin: 2 }}>Switch Camera</Button>
          </div>
          <div style={{ marginTop: '24px', textAlign: 'center'}}>
            <Button onClick={() => setOpenCamera(false)} variant="contained" color="success" size="medium" sx={{ width: 200, padding: 1, margin: 2 }}>Close Camera</Button>
                </div>
                </div>
              </div>
        </Paper>
      )}
        </Home>
    )
}

export default DocumentUpload
import React from 'react'
import Footer from './footer';
import Header from './header';
import { ScrollRestoration } from 'react-router-dom';

const Home = (props) => {
    const {children} = props;
    return (
        <div style={{background:'#F1F7F0'}}>
            <ScrollRestoration />
            <Header></Header>
            <div>
                {children}
            </div>
            <Footer></Footer>
        </div>
        
    )
}

export default Home
import { createBrowserRouter } from "react-router-dom";
import OTP from "../pages/OTP";
import Intro from "../pages/Intro";
import KYC from "../pages/KYC";
import DocumentUpload from "../pages/DocumentUpload";
import Bank from "../pages/Bank";
import Timer from "../pages/Timer";
import Tenure from "../pages/Tenure";
import Upfront from "../pages/Upfront";
import UpfrontSuccess from "../pages/UpfrontSuccess";
import Esign from "../pages/eSign";
import Enach from "../pages/eNach";
import EnachFailure from "../pages/eNachFailure";
import EnachSuccess from "../pages/eNachSuccess";
import PFDetails from "../pages/pfDetails";
import Status from "../pages/status";
import AssignedDetails from "../pages/assignedDetails";
import Esignurl from "../pages/eSignURL";
import Assignment from "../pages/Assignment";
import Assignmentsuccess from "../pages/assignmentsuccess";
import NotFound from "../pages/NotFound";
import PolicyDetails from "../pages/policyDetails";

const router = createBrowserRouter([
    {
        path: '/:id',
        element: <OTP/>
    },
    {
        path: '/intro',
        element: <Intro/>
    },
    {
        path: '/details',
        element: <PolicyDetails/>
    },
    {
        path: '/kyc',
        element: <KYC/>
    },
    {
        path: '/upload',
        element: <DocumentUpload/>
    },
    {
        path: '/bank',
        element: <Bank/>
    },
    {
        path: '/timer',
        element: <Timer/>
    },
    {
        path: '/tenure',
        element: <Tenure/>
    },
    {
        path: '/upfront',
        element: <Upfront/>
    },
    {
        path: '/upfrontsuccess',
        element: <UpfrontSuccess/>
    },
    {
        path: '/esign',
        element: <Esign/>
    },
    {
        path: '/enach',
        element: <Enach/>
    },
    {
        path: '/enachFailure',
        element: <EnachFailure/>
    },
    {
        path: '/enachSuccess',
        element: <EnachSuccess/>
    },
    {
        path: '/pfDetails',
        element: <PFDetails/>
    },
    {
        path: '/status',
        element: <Status/>
    },
    {
        path: '/assignedDetails',
        element: <AssignedDetails/>
    },
    {
        path: '/assignment',
        element: <Assignment/>
    },
    {
        path: '/esignurl',
        element: <Esignurl/>
    },
    {
        path: '/assignmentsuccess',
        element: <Assignmentsuccess/>
    },
    {
        path: '/notfound',
        element: <NotFound/>
    }
])

export default router;
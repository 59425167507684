import {HIDE_SNACK, SHOW_SNACK} from '../actions/types';

const initialState = {
  open: false,
  error: ''
};

const snackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACK:
      return Object.assign({}, state, {
        open: action.payload.open,
        error: action.payload.error
      });
    case HIDE_SNACK:
        return Object.assign({}, state, {
          open: false,
          error: ''
        });
    default:
      return state;
  }
};

export default snackReducer;

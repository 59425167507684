import Api from "../lib/Api"
import { AUTH_FAILURE, AUTH_SUCCESS, GENERATE_OTP_FAILURE, GENERATE_OTP_SUCCESS, SHOW_SNACK, VALIDATE_OTP_SUCCESS } from "./types"

export const authSuccess = () => async dispatch => {
    dispatch({
        type: AUTH_SUCCESS,
        payload: true
    })
}

export const authFailure = () => async dispatch => {
    dispatch({
        type: AUTH_FAILURE,
        payload: false
    })
}

export const generateOtp = (policyId) => async (dispatch) => {
  const body = policyId
  try {
    const response = await Api.post(body, '/auth/otp/generate');
    if (response.status === 'success') {
      dispatch({
        type: GENERATE_OTP_SUCCESS,
        payload: {...response.data,...policyId}
      })
        return true
    } else {
        dispatch({
            type: GENERATE_OTP_FAILURE,
        })
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
        return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
      return false
  }
}

export const validateOtp = body => async (dispatch) => {
  
  try {
    const response = await Api.post(body, '/auth/otp/validate');
    if (response.status === 'success') {
      dispatch({
        type: VALIDATE_OTP_SUCCESS,
        payload: response.data
      })
      return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
      return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false
  }
}


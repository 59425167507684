export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILURE = "AUTH_FAILURE"
export const TOGGLE_LOADER = "TOGGLE_LOADER"
export const LOGOUT = "LOGOUT"
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS'
export const GENERATE_OTP_FAILURE = 'GENERATE_OTP_FAILURE'
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS'
export const VALIDATE_OTP_FAILURE = 'VALIDATE_OTP_FAILURE'
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN'
export const SHOW_SNACK = 'SHOW_SNACK'
export const HIDE_SNACK = 'HIDE_SNACK'
export const VALIDATE_PAN_SUCCESS = 'VALIDATE_PAN_SUCCESS'
export const VALIDATE_PAN_FAILURE = 'VALIDATE_PAN_FAILURE'
export const VALIDATE_DOCUMENT = 'VALIDATE_DOCUMENT'
export const VALIDATE_DOCUMENT_FAILURE = 'VALIDATE_DOCUMENT_FAILURE'
export const CURRENT_JOURNEY_SELFIE = 'CURRENT_JOURNEY_SELFIE'
export const VALIDATE_BANK_SUCCESS = 'VALIDATE_BANK_SUCCESS'
export const VALIDATE_BANK_FAILURE = 'VALIDATE_BANK_FAILURE'
export const CREATE_ORDER_SUCCESS ='CREATE_ORDER_SUCCESS'
export const CAPTURE_ORDER_SUCCESS ='CAPTURE_ORDER_SUCCESS'
export const ENACH_GENERATED_SUCCESS ='ENACH_GENERATED_SUCCESS'
export const ENACH_GENERATED_FAILURE ='ENACH_GENERATED_FAILURE'
export const ESIGN_GENERATED_SUCCESS ='ESIGN_GENERATED_SUCCESS'
export const ESIGN_GENERATED_FAILURE ='ESIGN_GENERATED_FAILURE'
export const GET_LOAN_CUSTOM_EMI_SUCCESS ='GET_LOAN_CUSTOM_EMI_SUCCESS'
export const GET_LOAN_CUSTOM_EMI_FAILURE ='GET_LOAN_CUSTOM_EMI_FAILURE'
export const SUBMIT_LOAN_AMOUNT_SUCCESS ='SUBMIT_LOAN_AMOUNT_SUCCESS'
export const SUBMIT_LOAN_AMOUNT_FAILURE ='SUBMIT_LOAN_AMOUNT_FAILURE'
export const SUBMIT_ASSIGNMNET_SUCCESS ='SUBMIT_ASSIGNMNET_SUCCESS'
export const SUBMIT_ASSIGNMNET_FAILURE ='SUBMIT_ASSIGNMNET_FAILURE'
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { TextField, createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { formatCurrency, getTitle, maskBankAccNum } from '../helper/formatter';
import { getCustomEMIDetails, getUserDetails, selectTenure, submitAssignment } from '../actions/policyAction';
import { getStepsForNav } from '../helper/steps';

const Assignment = (props) => { 
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const policy = useSelector(state => state.Auth.policy)
    const loanInfo = useSelector(state => state.Auth.loanInfo)
    const bank=pathOr('',['bank'],policy)
     const getCreditAmount = () => {
        switch (pathOr('', ['optFor'], policy)) {
            case 'premiumFinance':
                return pathOr(0,['eligiblePremiumFinance'],loanInfo);
            case 'assignment':
                return pathOr(0,['surrenderValue'],loanInfo);
            default:
                return pathOr(0,['eligibleLoanAmount'],loanInfo);
        }
     }
     const getInterestRate = () => {
        switch (pathOr('', ['optFor'], policy)) {
            case 'premiumFinance':
                return pathOr(0,['interest'],loanInfo);
            case 'assignment':
                return pathOr(0,['surrenderValue'],loanInfo);
            default:
                return pathOr(0,['loanInterest'],loanInfo);
        }
     }
    
    const handleURL = async () => {
          const status = await dispatch(
            submitAssignment(),
            );
            if (status) { 
                navigate('/esign')
            }
    }
    
    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h2' style={{ color: colorsv1.primary.dark[900], marginBottom: '20px' }}>{"Congratulations !! "}</div>
                    <div className='h4' style={{ marginBottom: '16px' }}>{"Your policy is eligible for assignment"}</div>
                   
                
                    <PolicyCard />
                    
                    <div style={{background:colorsv1.primary.light[100],padding:'13px 16px',borderRadius:'6px',marginTop:'20px',flexDirection:'row',display:'flex'}}>
                        <div style={{ color: colorsv1.primaryColor }}>{"Surrender Value  "}</div>
                        <div style={{ color: colorsv1.primaryColor,marginLeft:'12px' }}>{ formatCurrency(Math.floor(pathOr(0,['surrenderValue'],policy)))}</div>
                    </div>
                    
                     <div style={{marginTop:'24px',borderRadius:'12px',background:colorsv1.grey[300],padding:'16px'}}>
                        <div className='h4'>{"Bank details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '8px 0 16px 0' }}></div>
                        <div className={'h4'} style={{marginBottom:'12px'}}>{pathOr('',['accountHolderName'],bank)}</div>
                        <div className='lightFont h5' style={{ marginBottom: '12px' }}>{"IFSC Code -  "}{pathOr('',['ifsc'],bank).toUpperCase()}</div>
                        <div className='lightFont h5' style={{ marginBottom: '12px' }}>{"A/c No. -  "}{ maskBankAccNum(pathOr('',['accountNumber'],bank))}</div>
                        <div className='lightFont h5'>{pathOr('',['bankName'],bank)}</div>
                    </div>
    

                   
                    {pathOr('', ['bank', 'accountNumber'], policy) != '' && (
                      <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                        <Button onClick={() => {  handleURL()}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>{(pathOr('', ['optFor'], policy) == 'premiumFinance' && pathOr(0, ['upfront'], loanInfo)>0)?'Make Payment':'Next'}</Button>
                </div>
)}
                    
                   

                </Paper>
            </Container>
        </Home>
    )
}

export default Assignment
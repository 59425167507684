import {LOGOUT, AUTH_FAILURE, AUTH_SUCCESS,CAPTURE_ORDER_SUCCESS,CREATE_ORDER_SUCCESS,ENACH_GENERATED_SUCCESS,ESIGN_GENERATED_SUCCESS,GENERATE_OTP_FAILURE, GENERATE_OTP_SUCCESS, UPDATE_AUTH_TOKEN, VALIDATE_OTP_SUCCESS,SUBMIT_LOAN_AMOUNT_SUCCESS, SUBMIT_ASSIGNMNET_SUCCESS } from "../actions/types"
const initialState = {
    isLoggedIn: false,
    isValidPolicy: false,
    user: {},
    policy: {},
    loanInfo: {},
    steps: {},
    tenure:{},
    enach:{},
    esign: {},
    accessToken: '',
    policyId:'',
}
const authReducer = (state=initialState, action) => {
    switch(action.type){
        case AUTH_SUCCESS:
            return Object.assign({},state, {
                isLoggedIn: action.payload
            })
        case AUTH_FAILURE:
            return Object.assign({},state, {
                isLoggedIn: false
            })
        case GENERATE_OTP_SUCCESS:
            return Object.assign({},state, {
                isValidPolicy: true,
                user: {},
                policy: {},
                loanInfo: {},
                steps: {},
                policyId: action.payload.policyId,
                tenure:{},
                enach:{},
                esign: {},
            })
        case LOGOUT:
            return Object.assign({},state, {
                isValidPolicy: false,
                user: {},
                policy: {},
                loanInfo: {},
                steps: {},
                accessToken: '',
                tenure:{},
    enach:{},
    esign: {},
            })
        case GENERATE_OTP_FAILURE:
            return Object.assign({},state, {
                isValidPolicy: false
            })
        case CREATE_ORDER_SUCCESS:
            return Object.assign({},state, {
                tenure: action.payload
            })
        case CAPTURE_ORDER_SUCCESS:
            return Object.assign({},state, {
                tenure: action.payload
            })
        case ENACH_GENERATED_SUCCESS:
            return Object.assign({},state, {
                enach: action.payload
            })
        case ESIGN_GENERATED_SUCCESS:
            return Object.assign({},state, {
                esign: action.payload
            })
        case SUBMIT_LOAN_AMOUNT_SUCCESS:
            return Object.assign({},state, {
                esign: action.payload
            })
        case SUBMIT_ASSIGNMNET_SUCCESS:
            return Object.assign({},state, {
                esign: action.payload
            })
        case VALIDATE_OTP_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.user,
                policy: action.payload.policy,
                loanInfo: action.payload.loanInfo,
                steps: action.payload.steps,
                isLoggedIn:true,
            })
        case UPDATE_AUTH_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.payload.accessToken
            })
        default:
            return state
    }
}

export default authReducer
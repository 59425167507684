import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate, useParams } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { formatCurrency, getTitle } from '../helper/formatter';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { captureUpfrontOrder, createUpfrontOrder } from '../actions/policyAction';


const Upfront = (props) => {

    const navigate = useNavigate()
    const policy = useSelector(state => state.Auth.policy)
    const loanInfo = useSelector(state => state.Auth.loanInfo)
    const orderData = useSelector(state => state.Auth.tenure)
     const dispatch = useDispatch()
    const [type, setType] = useState('NETBANKING');
    const { status } = useParams()


    function loadScript(src) {
      return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
              resolve(true);
          };
          script.onerror = () => {
              resolve(false);
          };
          document.body.appendChild(script);
      });
}

    const handleRazorPay = async (val, mode = 'full') => {
      await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );
   const status = await dispatch(
          createUpfrontOrder({policyId: pathOr('', ['_id'], val), mode}),
        );
        if (!status) {
            alert('Server error. Are you online?');
            return false;
          }
          // console.log(status)
          const {amount, id: order_id, currency} = status;
          const options = {
            key: 'rzp_test_nZdmhpGfqsKixQ', // Enter the Key ID generated from the Dashboard
             amount: amount.toString(),
            currency: currency,
            name: 'Briezi',
            description:
              'Upfront payment towards policy number ' +
              pathOr('', ['policyNumber'], policy),
            order_id: order_id,
            prefill: {
              name: policy.policyHolderName,
              contact: pathOr('', ['policyHolderMobileNumber'], policy),
            },
            handler: async function (paymentdata) {
                const { razorpay_payment_id } = paymentdata;
                // console.log(paymentdata)
                const data = {
                  amount: amount.toString(),
                  currency,
                  orderId:order_id,
                  paymentId: razorpay_payment_id,
                  policyId: pathOr('', ['_id'], val),
                };
                const paymentStatus = await dispatch(captureUpfrontOrder(data));
                // console.log(paymentStatus)
                if (paymentStatus) {
                    navigate('/esign',{replace: true, state:{upfront:'success'}})
                } else {
                    handleRazorPay(policy);
                }
              },
          
            theme: {
              color: '#000000',
            },
          };
          const rzp1 = new window.Razorpay(options);

          rzp1.on("payment.failed", function (response) {
            alert(response.error.description);
          });
        
          rzp1.open();
    }
    useEffect(() => {
        if(pathOr('', ['_id'], policy)!='' && pathOr('',['id'],orderData)){
            handleRazorPay(policy);
        }
      }, [dispatch, policy._id]);

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h2'>{"Upfront to be paid - "}{ formatCurrency(pathOr(0,['upfront'],loanInfo))}</div>

                </Paper>
            </Container>
        </Home>
    )
}

export default Upfront
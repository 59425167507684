import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import colorsv1 from '../config/colorsv1';
import './index.css'
import moment from 'moment';

const Footer = (props) => {
    return (
        <Container style={{    height: '224px'}}>
            <div className='footer' style={{color: "gray", position: "fixed", bottom: 0,left:0,width:'100%',padding:'0 32px'}}>
                {/* <div>Powered by </div>
                <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/webview_logo.png'
                    alt={"logo"} 
                    loading="lazy" style={{width:undefined,height:'18px',alignItems:'flex-start',marginRight:'18px',marginLeft:'18px'}}
                ></img> */}
                <div className='body activeText'>{`Copyright © ${moment().format('YYYY')} Briezi`}</div>
                <div style={{display:'flex',marginRight:'62px'}}>
                    <div className='body activeText'><a className='body activeText' style={{textDecoration:'none'}} target='_blank' href='https://www.briezi.com/privacy'>{"Privacy Policy"}</a></div>
                    <div className='body activeText' style={{marginLeft:'24px'}}><a   style={{textDecoration:'none'}} className='body activeText'  target='_blank' href='https://www.briezi.com/terms'>{"Terms & Conditions "}</a></div>
                </div>
            </div>
        </Container>
        
    )
}

export default Footer
import Api from "../lib/Api"
import { CAPTURE_ORDER_SUCCESS, CREATE_ORDER_SUCCESS, CURRENT_JOURNEY_SELFIE, ENACH_GENERATED_SUCCESS, ESIGN_GENERATED_SUCCESS, GET_LOAN_CUSTOM_EMI_SUCCESS, SHOW_SNACK, SUBMIT_ASSIGNMNET_SUCCESS, SUBMIT_LOAN_AMOUNT_SUCCESS, VALIDATE_BANK_SUCCESS, VALIDATE_DOCUMENT, VALIDATE_OTP_SUCCESS, VALIDATE_PAN_SUCCESS } from "./types";

export const validatePAN = body => async (dispatch) => {
  
  try {
    const response = await Api.post(body, '/kyc/validate/pan');
    if (response.status === 'success') {
      dispatch({
        type: VALIDATE_PAN_SUCCESS,
        payload: response.data
      })
        dispatch(getUserDetails());
      return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
      return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false
  }
}

export const validateDocument = body => async dispatch => {
  try {
    const res = await Api.post(body, '/kyc/validate/aadhaardocument');
    if (res.status === 'success') {
      dispatch({
        type: VALIDATE_DOCUMENT,
        payload: res.data,
      });
        dispatch(getUserDetails());
      return res.data.message;
    } else {
      //failureToast(res.data.error || res.data.message);
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: res.data.message || res.data.error
        }
      })
      return false;
    }
  } catch (e) {
    //failureToast(e.message);
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};

export const validateSelfie = body => async dispatch => {
  try {
    const status = await Api.post(body, '/kyc/validate/selfie');
    if (status.status === 'success') {
      dispatch({
        type: CURRENT_JOURNEY_SELFIE,
        payload: body.photo,
      });
        dispatch(getUserDetails());
      return true;
    } else {
      //failureToast(status.data.error || status.data.message);
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: status.data.message || status.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};

export const validateBank = body => async dispatch => {
  try {
    const res = await Api.post(body, '/kyc/validate/bank');
    if (res.status === 'success') {
      dispatch({
        type: VALIDATE_BANK_SUCCESS,
        payload: res.data,
      });
        dispatch(getUserDetails());
      return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: res.data.message || res.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};

export const selectTenure = order => async dispatch => {
  const body = order;
  try {
    const response = await Api.post(body, '/premiumFinance');
    if (response.status === 'success') {
      dispatch({
        type: CREATE_ORDER_SUCCESS,
        payload: response.data,
      });
        dispatch(getUserDetails());
      return response.data;
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};

export const createUpfrontOrder = order => async dispatch => {
  const body = order;
  try {
    const response = await Api.post(body, '/payment/create/upfront');
    if (response.status === 'success') {
      dispatch({
        type: CREATE_ORDER_SUCCESS,
        payload: response.data,
      });
        dispatch(getUserDetails());
      return response.data;
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};
export const captureUpfrontOrder = order => async dispatch => {
  const body = order;
  try {
    const response = await Api.post(body, '/payment/fetch/upfront');
    if (response.status === 'success') {
      dispatch({
        type: CAPTURE_ORDER_SUCCESS,
        payload: response.data,
      });
        dispatch(getUserDetails());
      return true;
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};

export const generateEnach = body => async dispatch => {
  try {
    const premiumFinance = await Api.post(body, '/premiumFinance/eNach');
    if (premiumFinance.status === 'success') {
       dispatch({
        type: ENACH_GENERATED_SUCCESS,
        payload: premiumFinance.data,
       });
        dispatch(getUserDetails());
      return premiumFinance;
    } else {
      //failureToast(premiumFinance.data.error || premiumFinance.data.message);
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: premiumFinance.data.message || premiumFinance.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};

export const createEsign = order => async dispatch => {
  const body = order;
  try {
    const response = await Api.post(body, '/esign/premiumfinance');
    if (response.status === 'success') {
      dispatch({
        type: ESIGN_GENERATED_SUCCESS,
        payload: response.data,
      });
        dispatch(getUserDetails());
      return response.data;
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
    return false;
  }
};



export const getUserDetails = () => async (dispatch) => {
  try {
    const response = await Api.get('/auth/me');
    if (response.status === 'success') {
      dispatch({
        type: VALIDATE_OTP_SUCCESS,
        payload: response.data
      })
        return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
        return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
      return false
  }
}

export const getCustomEMIDetails = body => async (dispatch) => {
  try {
    const response = await Api.post(body,'/loan/emi');
    if (response.status === 'success') {
      dispatch({
        type: VALIDATE_OTP_SUCCESS,
        payload: response.data
      })
        return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
        return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
      return false
  }
}

export const submitReqLoanAmount = body => async (dispatch) => {
  try {
    const response = await Api.post(body,'/loan');
    if (response.status === 'success') {
      dispatch({
        type: SUBMIT_LOAN_AMOUNT_SUCCESS,
        payload: response.data
      })
        return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
        return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
      return false
  }
}

export const submitAssignment = () => async (dispatch) => {
  try {
    const response = await Api.post({},'/assignment');
    if (response.status === 'success') {
      dispatch({
        type: SUBMIT_ASSIGNMNET_SUCCESS,
        payload: response.data
      })
        return true
    } else {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          open: true,
          error: response.data.message || response.data.error
        }
      })
        return false
    }
  } catch (e) {
    dispatch({
      type: SHOW_SNACK,
      payload: {
        open: true,
        error: e.message
      }
    })
      return false
  }
}
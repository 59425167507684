import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import colorsv1 from '../config/colorsv1';
import './index.css'
import { Typography } from '@mui/material';

const Header = (props) => {
    return (
        <Container maxWidth="lg">
            <div className='header' style={{ position: "fixed", top: 0,left:0,width:'100%'}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className='headerBrieziLogo'>
                <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/BBlogo.png'
                    alt={"logo"} 
                    loading="lazy" style={{width:undefined,height:'62px',alignItems:'flex-start',marginRight:'18px',marginLeft:'18px'}}
                    ></img>
                    <Typography>{"BRIEZI"}</Typography>
                </div>
                </Grid>
                    <Grid item xs={6}>
                        <div className='headerBrieziLogo' style={{float: 'right'}}>
                 <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Logos/Max%20Life%20Insurance.png'
                            alt={"logo"} 
                            loading="lazy" style={{width:'84px',height:'56px',alignItems:'flex-start',marginRight:'20px'}}
                        ></img>
            </div>
                </Grid>
                </Grid> 
                
                
            </div>
            
        </Container>
        
    )
}

export default Header
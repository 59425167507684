import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { Alert, Checkbox, FormControlLabel, FormGroup, Stack, createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { formatCurrency, getTitle, maskBankAccNum } from '../helper/formatter';
import { getSteps } from '../helper/steps';
import { canProceed } from '../helper/canProceed';
import { submitAssignment } from '../actions/policyAction';


const PolicyDetails = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [otp, setOtp] = useState('');
    const platform = window.navigator.platform
    const steps = useSelector(state => state.Auth.steps)
    const policy = useSelector(state => state.Auth.policy)
    const user = useSelector(state => state.Auth.user)
    console.log(policy)

const [isAgree, setAgree] = useState(true);
const handleURL = async () => {
    const status = await dispatch(
      submitAssignment(),
      );
      if (status) { 
          navigate('/esign')
      }
}
    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 

                
<PolicyCard/>
                
                    <div style={{marginTop:'15px',borderRadius:'6px',padding:'16px',background:colorsv1.primary.light[200]}}>
                        <Typography variant='h6' style={{color:colorsv1.primaryColor}}>{`Surrender Value - ${formatCurrency(Math.floor(policy.surrenderValue))} `}</Typography>
                    </div>


                    <div className='detailsRmPd' style={{background:colorsv1.white }}>
                        <Typography style={{ marginLeft: '24px' }}>{"CONFIRM YOUR DETAILS"}</Typography>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{ height:'2px',background: '#D9D9D9',flex:0.9 }}></div>
                             <img src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/verify.png'
                                    alt={"logo"} 
                                    loading="lazy" style={{width:'42px',height:'42px',alignItems:'center'}}
                                ></img>
                        </div>
                        <div style={{ padding: '22px' }}>
                            <div style={{display:'flex'}}>
                                <div
                                    style={{height: 50, width: 50, backgroundRepeat:'no-repeat', backgroundSize:'contain' ,backgroundImage:`url(${policy.photoUrl})`}}
                                ></div>
                                <div style={{marginLeft:'18px'}}>
                                    <Typography variant='caption' style={{}}>{'FULL NAME'}</Typography>
                                    <Typography variant='h5' style={{}}>
                                    {policy.pan.fullName}
                                    </Typography>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'32px'}}>
                                    <div style={{flex:0.5}}>
                                        <Typography variant='caption'>
                                        {'AADHAAR NUMBER '}
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                        {maskBankAccNum(policy.pan.maskedAadhaar)}
                                        </Typography>
                                    </div>
                                    <div style={{flex:0.5}}>
                                        <Typography variant='caption'>{'PAN NUMBER'}</Typography>
                                        <Typography variant='subtitle1'>
                                        {policy.pan.panNum}
                                        </Typography>
                                    </div>
                            </div>
                            <div style={{display:'flex',marginTop:'32px'}}>
                                    <div style={{flex:0.5}}>
                                        <Typography variant='caption'>
                                        {'DATE OF BIRTH'}
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                        {policy.pan.dob}
                                        </Typography>
                                    </div>
                                    <div style={{flex:0.5}}>
                                        <Typography variant='caption'>{'GENDER'}</Typography>
                                        <Typography variant='subtitle1'>
                                        {policy.pan.gender}
                                        </Typography>
                                    </div>
                            </div>
                            <div style={{display:'flex',marginTop:'32px'}}>
                                    <div style={{flex:0.5}}>
                                        <Typography variant='caption'>
                                        {'Bank account NUmber'}
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                        {maskBankAccNum(policy.bank.accountNumber)}
                                        </Typography>
                                    </div>
                                    <div style={{flex:0.5}}>
                                        <Typography variant='caption'>{'IFSC CODE'}</Typography>
                                        <Typography variant='subtitle1'>
                                        {policy.bank.ifsc}
                                        </Typography>
                                    </div>
                            </div>
                        </div>
                        <div style={{marginBottom:'24px', height: '2px', background: '#D9D9D9' }}></div>
                        <Typography style={{ marginLeft: '22px' }} variant='caption'>{"By checking the box below, you confirm that the details provided above are accurate."}</Typography>
                        <FormGroup style={{ padding: '0 22px' }}>
                            <FormControlLabel control={<Checkbox defaultChecked onChange={ ()=>{setAgree(!isAgree)}} />} label="I confirm that the above details are correct." />
                             <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                        <Button disabled={!isAgree} onClick={async () => {
                            // const step = getSteps(steps,policy);
                            // navigate(step)
                            await handleURL()
                    }} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Proceed to E-Sign</Button>
                    </div>
                        </FormGroup>
                 </div>
                    

                </Paper>
            </Container>
        </Home>
    )
}

export default PolicyDetails
import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import colorsv1 from '../config/colorsv1';
import { TextField } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import StatusCheck from '../components/StatusCheck';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { canProceed } from '../helper/canProceed';
import { getUserDetails, validateBank } from '../actions/policyAction';
import { getTitle } from '../helper/formatter';
import { getStepsForNav } from '../helper/steps';

const Bank = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const steps = useSelector(state => state.Auth.steps)
  const policy = useSelector(state => state.Auth.policy)
  const [bank, setBank] = useState('');
      const loanInitialState = {
    ifsc: '',
    accountNumber: '',
    reAccountNumber: '',
    bankName: '',
  };
  const errorInitialState = {
    ifsc: {
      hasError: true,
      errorMessage: '',
    },
    accountNumber: {
      hasError: true,
      errorMessage: '',
    },
    reAccountNumber: {
      hasError: true,
      errorMessage: '',
    },
    bankName: {
      hasError: false,
      errorMessage: '',
    },
  };
  const [loanInfo, setLoanInfo] = useState(loanInitialState);
  const [error, setError] = useState(errorInitialState);
    const updateForm = (val, name) => {
    setLoanInfo({
      ...loanInfo,
      [name]: val,
    });
    };
   const fetchBank = async val => {
    try {
      const request = await fetch('https://ifsc.razorpay.com/' + val);
      const response = await request.json();
      if (pathOr('', ['BANK'], response) === '') {
        setError({
          ...error,
          ifsc: {
            hasError: true,
            errorMessage: 'Enter valid ifsc',
          },
        });
      } else {
        setBank(response.BANK);
        accRef.current.focus();
        setError({
          ...error,
          ifsc: {
            hasError: false,
            errorMessage: '',
          },
        });
      }
    } catch (e) {
      setError({
        ...error,
        ifsc: {
          hasError: true,
          errorMessage: 'Enter valid ifsc',
        },
      });
      console.log(e.message);
    }
   };
  
   const handleProceed = async () => {
    const body = {
      accountNumber: loanInfo.accountNumber,
      bankName: bank,
      ifsc: loanInfo.ifsc,
      policyId: policy._id,
    };
 
    const status = await dispatch(
      validateBank(body),
    );
    if (status) {
        navigate('/timer');
      } 
    //  navigate('/timer');
   };
  
       useEffect(() => {
         const step = getStepsForNav(steps, policy, 'bank');
         if(step!='')
                            navigate(step)
     }, [dispatch,steps])
    const ifscRef = useRef(null);
  const accRef = useRef(null);
  const reAccRef = useRef(null);
    const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.target === ifscRef.current) {
        accRef.current.focus();
      }
      if (event.target === accRef.current) {
        reAccRef.current.focus();
      }
    }
    };
  
          const handleAuthMe = async() => {
           const status = await dispatch(
          getUserDetails(),
           );
      
    }
      useEffect(() => {
          handleAuthMe();
      }, [dispatch]);

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 

                
<PolicyCard/>
                

                    <div className='body mediumFont' style={{marginTop:'24px'}}>{"Complete your KYC"}</div>

                    <StatusCheck step={3} />

                    <div style={{ background: colorsv1.grey[200], padding: '24px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
              <TextField
                    required
                    inputRef={ifscRef}
                    // onKeyDown={handleKeyDown}
                id="ifsc"
                name="ifsc"
                label="Enter IFSC"
                fullWidth
                autoComplete="given-name"
                    variant="standard"
                    inputProps={{
        style: {
          textTransform: 'uppercase',
        },
      }}

                    value={loanInfo.ifsc}
                    error={error.ifsc.hasError}
                    helperText={error.ifsc.errorMessage}
                onChange={(evt) => {
                  updateForm(evt.target.value, evt.target.name)
                  if (!new RegExp('^[A-Za-z]{4}[a-zA-Z0-9]{7}$').test(evt.target.value)) { 
setError({
                      ...error,
                      ifsc: {
                        hasError: true,
                        errorMessage: '',
                      },
                    })
                  } else {
                     setError({
                      ...error,
                      ifsc: {
                        hasError: false,
                        errorMessage: '',
                      },
                    })
                  }
                }}
                onBlur={evt => {
                  fetchBank(evt.target.value);
                }}
                onKeyDown={evt => {
                  fetchBank(evt.target.value);
                }}
              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                            <TextField
                    required
                                id="bankName"
                                name="bankName"
                                label="Bank name"
                                fullWidth
                                autoComplete="bankName"
                                variant="standard"
                                value={bank}
                                InputProps={{
                                readOnly: true,
                                }}
                    InputLabelProps={{ shrink: true }}  
                            />
                            </Grid>
                             <Grid item xs={12} sm={12}>
              <TextField
                    required
                    inputRef={accRef}
                    onKeyDown={handleKeyDown}
                id="accNumber"
                name="accountNumber"
                label="Enter Account number"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                    type={'password'}
                     value={loanInfo.accountNumber}
                    error={error.accountNumber.hasError}
                    helperText={error.accountNumber.errorMessage}
                onChange={(event) => {
                  updateForm(event.target.value, event.target.name);
                  if (!new RegExp('^[0-9]{1,}?').test(event.target.value)) {
                    setError({
                      ...error,
                      [event.target.name]: {
                        hasError: true,
                        errorMessage: 'Enter valid account number'
                      }
                    })
                  } else {
                    setError({
                      ...error,
                      [event.target.name]: {
                        hasError: false,
                        errorMessage: ''
                      }
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                    required
                    inputRef={reAccRef}
                id="reAccNumber"
                name="reAccountNumber"
                label="Re-enter Account number"
                fullWidth
                autoComplete="given-name"
                variant="standard"
 value={loanInfo.reAccountNumber}
                    error={error.reAccountNumber.hasError}
                    helperText={error.reAccountNumber.errorMessage}
                onChange={(event) => {
                  updateForm(event.target.value, event.target.name);
                  if (event.target.value != loanInfo.accountNumber) {
                    setError({
                      ...error,
                      [event.target.name]: {
                        hasError: true,
                        errorMessage: 'Account number mismatch'
                      }
                    })
                  } else {
                    setError({
                      ...error,
                      [event.target.name]: {
                        hasError: false,
                        errorMessage: ''
                      }
                    })
                  }
                }}
              />
            </Grid>
                        </Grid>
                    </div>

                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    <Button disabled={canProceed(error)} onClick={()=>{handleProceed()}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default Bank
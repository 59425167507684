const colorsv1 = {
  main: '#218B03',
  primaryColor: '#4DA235',
  secondary: '#4DA235',
  background: '#F1F7F0',
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    light: {
      100: '#EDF6EB',
      200: '#DCECD8',
      300: '#CBE3C4',
      400: '#B9D9B1',
      500: '#A7D09D',
      600: '#96C789',
      700: '#85BD76',
      800: '#73B462',
      900: '#61AA4F',
    },
    dark: {
      100: '#081006',
      200: '#10200C',
      300: '#183012',
      400: '#204018',
      500: '#28501D',
      600: '#306123',
      700: '#387129',
      800: '#40812F',
      900: '#489135',
    },
  },
  grey: {
    100: '#ffffff',
    200: '#FAFAFA',
    300: '#F5F5F5',
    400: '#F1F1F1',
    500: '#C3C3C3',
    600: '#939393',
    700: '#636363',
    800: '#333333',
    900: '#0F0F0F',
  },
  notification: {
    dark: {
      info: '#1890FF',
      success: '#52C41A',
      warning: '#FAAD14',
      error: '#FF4D4F',
    },
    light: {
      info: '#D8DFEC',
      success: '#DCECD8',
      warning: '#ECEAD8',
      error: '#ECD8D8',
    },
  },
};

export default colorsv1;

import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { FormControlLabel, Radio, RadioGroup, createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { pathOr } from 'ramda';
import { formatCurrency, getTitle, maskBankAccNum } from '../helper/formatter';
import { useDispatch, useSelector } from 'react-redux';
import { generateEnach, getUserDetails } from '../actions/policyAction';
import useRazorpay from 'react-razorpay';
import { getStepsForNav } from '../helper/steps';


const Enach = (props) => {
    const navigate = useNavigate()
    const [type, setType] = useState('NETBANKING');
    const policy = useSelector(state => state.Auth.policy)
    const tenure = useSelector(state => state.Auth.tenure)
    const enach = useSelector(state => state.Auth.enach)
    const Razorpay = useRazorpay();
    const dispatch = useDispatch()
    
//         const steps = useSelector(state => state.Auth.steps)
//  useEffect(() => {
//    const step = getStepsForNav(steps, policy, 'enach');
//    if(step!='')
//                             navigate(step)
//      }, [dispatch])

        const handleAuthMe = async() => {
           const status = await dispatch(
          getUserDetails(),
           );
      
    }
      useEffect(() => {
        handleAuthMe();
      }, [dispatch]);
    
    const setupEnach = async () => {
        const status = await dispatch(
            generateEnach({
                policyId: policy._id,
                mode: type.toLowerCase(),
            }),
        );
        if (status) {
            //alert(JSON.stringify(status))
            //console.log(status.data.short_url)
            window.location.href  = status.data.short_url;
            
        } else {
            //failureToast('Unable to setup eMandate');
        }
     
    };

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h3'>{"Setup auto - debit for EMIs"}</div>
                    <div className='body' style={{marginTop:'8px',marginBottom:'24px'}}>{"EMI of  "+formatCurrency(pathOr(0,[pathOr('', ['optFor'], policy),'emi'],policy))+" will be auto debited"}</div>
                
                
                    <PolicyCard />

                    <div style={{ marginTop:'24px',borderRadius: '12px', background: colorsv1.grey[300], padding: '16px' }}>
                        <div className='h3'>{"e-NACH: Bank details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '8px 0 16px 0' }}></div>
                        <div className='body'>{pathOr('',['bank','bankName'],policy)}</div>
                        <div className='body' style={{marginTop:'16px'}}>{"A/C "+maskBankAccNum(pathOr('',['bank','accountNumber'],policy))}</div>
                    </div>

                    <div style={{ marginTop: '24px', borderRadius: '12px', background: colorsv1.grey[300], padding: '16px' }}>
                        <div className='h3'>{"Verify using "}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '8px 0 16px 0' }}></div>
                        <RadioGroup
                            row
                            value={type}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="mode"
                                        onChange={(event) => setType(event.target.value)}
                                    >
                                        <FormControlLabel value="NETBANKING" control={<Radio />} label="NET BANKING" />
                                        <FormControlLabel value="DEBITCARD" control={<Radio />} label="DEBIT CARD" />
                                    </RadioGroup>
                    </div>
                    
                


                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    <Button onClick={() => { setupEnach() }} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default Enach
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { o, pathOr } from 'ramda';
import PolicyCardStatus from '../components/PolicyCardStatus';
import { getTitle } from '../helper/formatter';
import { useDispatch, useSelector } from 'react-redux';
import { getStepsForNav } from '../helper/steps';

const Status = (props) => {
    const navigate = useNavigate()
    const policy = useSelector(state => state.Auth.policy)
    const accessToken = useSelector(state => state.Auth.accessToken)
    const dispatch = useDispatch()

    const steps = useSelector(state => state.Auth.steps)
 useEffect(() => {
   const step = getStepsForNav(steps, policy, 'status');
   if(step!='')
                            navigate(step)
 }, [dispatch, steps])

    useEffect(() => {
        if (accessToken=='') {
                navigate('/notfound')
        }
        if (pathOr('incomplete',[pathOr('',['optFor'],policy),'status'],policy)=='incomplete') {
             navigate('/notfound')
        }
           if (pathOr('', [pathOr('', ['optFor'], policy), 'status'], policy) == 'approved' && pathOr('', ['optFor'], policy)=='premiumFinance') {
            navigate('/pfDetails');
           } else if (pathOr('', [pathOr('', ['optFor'], policy), 'status'], policy) == 'approved' && pathOr('', ['optFor'], policy)=='loan') {
               
           } else if (pathOr('', [pathOr('', ['optFor'], policy), 'status'], policy) == 'approved' && pathOr('', ['optFor'], policy)=='assignment') {
               navigate('/assignedDetails');
           } else if (pathOr('pending', [pathOr('', ['optFor'], policy), 'status'], policy) == 'pending') {
               
           }
           else {
               navigate('/notfound')
           }
      }, [dispatch]);
    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 

                
                    <PolicyCardStatus type={pathOr('', ['optFor'], policy)} status={pathOr('pending',[pathOr('', ['optFor'], policy),'status'],policy)} />
                    
                    {/* status pending */}
                    {pathOr('pending',[pathOr('', ['optFor'], policy),'status'],policy) == 'pending' && (
                         <div style={{textAlign:'center',marginTop:'54px'}}>
                        <img
                        style={{width:'145px',height:'120px'}}
                            src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Assignmentv2.png' />
                        <div style={{color:colorsv1.primary.dark[900],marginTop:'24px'}}>{"Currently, the application is being processed"}</div>
                    </div>
                    )}
                    {/* status rejected */}
                    {pathOr('',[pathOr('', ['optFor'], policy),'status'],policy) == 'rejected' && (
                         <div style={{textAlign:'center',marginTop:'54px'}}>
                        <img
                        style={{width:'145px',height:'120px'}}
                            src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Rejectedweb.png' />
                        <div style={{color:colorsv1.notification.dark.error,marginTop:'24px'}}>{"Your Request for premium finance is rejected"}</div>
                    </div>
                    )}
                    
                   

                    
                    {pathOr('', [pathOr('', ['optFor'], policy), 'status'], policy) == 'approved' && (
                        <div style={{ marginTop: '24px', textAlign: 'center', marginBottom: '32px' }}>
                            <Button onClick={() => { navigate('/assignedDetails'); }} variant="contained" color="success" size="medium" sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                        </div>
                    )}

                </Paper>
            </Container>
        </Home>
    )
}

export default Status
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate, useParams } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PolicyCardStatus from '../components/PolicyCardStatus';
import { generateOtp } from '../actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { getTitle } from '../helper/formatter';
import { pathOr } from 'ramda';


const AssignedDetails = (props) => {
    const navigate = useNavigate()
const policy = useSelector(state => state.Auth.policy)

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div className='h3' style={{marginBottom:'16px'}}>{"Assignment Details"}</div>

                 <PolicyCardStatus type='assignment' status='approved'/>
               
                    <div style={{marginTop:'24px',borderRadius:'12px',background:colorsv1.grey[300],padding:'16px'}}>
                        <div className='h4'>{"Nominee details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '8px 0 16px 0' }}></div>
                        <div className={'h4'} style={{marginBottom:'12px'}}>{"Naveen"}</div>
                        <div className='lightFont h5' style={{marginBottom:'12px'}}>{"relation -  Brother"}</div>
                        <div className='lightFont h5' style={{marginBottom:'12px'}}>{"mobile - 7090070034 "}</div>
                        <div className='lightFont h5'>{"email -  Naveen@gmail.com "}</div>
                    </div>
                    <div style={{marginTop:'24px',borderRadius:'12px',background:colorsv1.grey[300],padding:'16px'}}>
                        <div className='h4'>{"Transaction details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '8px 0 16px 0' }}></div>
                        <div className='lightFont h5' style={{marginBottom:'12px'}}>{"Transaction Id - 466568998996598"}</div>
                        <div className='lightFont h5' style={{marginBottom:'12px'}}>{"Date & time - 05-04-2201  11.30 AM"}</div>
                        <div className='lightFont h5' style={{marginBottom:'12px'}}>{"Amount -  6,55,473"}</div>
                        <div className='rowClass justifyBetween'>
                            <div className='lightFont h5'>{"Mode  -  Net Banking "}</div>
                        <div className='lightFont h5' style={{color:colorsv1.primary.light[900]}}>{"Status  -  Successful "}</div>
                        </div>
                    </div>
                    <div className='rowClass justifyBetween' style={{ marginTop: '24px', borderRadius: '4px', padding: '14px 16px',border:'1px solid #ABABAB' }}>
                        <div className='body'>{"Download agreement"}</div>
                        <div>
                                <FileDownloadOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                            </div>
                    </div>

                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    <Button onClick={()=>{navigate('/status');}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default AssignedDetails
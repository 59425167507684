import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './config/router'
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect } from 'react';
import { pathOr } from 'ramda';
import { HIDE_SNACK } from './actions/types';
import { Backdrop, CircularProgress } from '@mui/material';
import { getScreen } from './helper/steps';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  
  //const state = store.getState();
  const loader = useSelector(state => pathOr(false,['Loader','loader'],state))
   const open =useSelector(state => state.Snack.open)
  const error = useSelector(state => state.Snack.error)
    const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: HIDE_SNACK
      })
    },6000)
  },[open])
  return (
    <HelmetProvider>
       <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}>
        <CircularProgress color="inherit" />
       </Backdrop>
        <RouterProvider router={router} />
        <Snackbar
          anchorOrigin={{ vertical:getScreen(window.location.href.split('/')[3])?'bottom': 'top', horizontal: 'center' }}
          open={open}
          message={error}
        />
    </HelmetProvider>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { createEsign } from '../actions/policyAction';
import { ExternalLink } from 'react-external-link';
import Iframe from 'react-iframe'
import { getTitle } from '../helper/formatter';

const Esignurl = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const policy = useSelector(state => state.Auth.policy)
    const esign = useSelector(state => state.Auth.esign)

// const Iframe=(props)=> {
//   return (
//     <div
//       dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
//     />
//   );
// }
    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h2'>{"e-Sign"}</div>
                    <div style={{ border: '0.5px solid #ABABAB',marginTop:'12px',marginBottom:'24px' }}></div>
                
                    <PolicyCard />
                    
                    <ExternalLink href={esign.url} />
             
            <Iframe url={esign.url}
                      width="640px"
        height="320px"
        id=""
        className=""
        display="block"
        position="relative"/>
           
                </Paper>
            </Container>
        </Home>
    )
}

export default Esignurl
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import colorsv1 from '../config/colorsv1';
import '../pages/index.css'
import { Typography } from '@mui/material';

const StatusCheck = (props) => {
     const {
    step
  } = props;
    return (
        <div style={{ display: 'flex',alignItems:'center',marginTop:'14px',marginBottom:'17px' }}>
                                <div style={{background:step>1?colorsv1.primary.light[800]: colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',marginRight:'10px'}} className='subTitles'>{1}</div>
                        <div className='body'>{'PAN'}</div>
                         <div style={{background:step>2?colorsv1.primary.light[800]:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',margin:'10px 10px 10px 32px'}} className='subTitles'>{2}</div>
                        <div className='body'>{'KYC'}</div>
                         <div style={{background:step>3?colorsv1.primary.light[800]:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',margin:'10px 10px 10px 32px'}} className='subTitles'>{3}</div>
                                <div className='body'>{'Bank Details'}</div>
                            </div>
        
    )
}

export default StatusCheck
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSelector } from 'react-redux';
import { formatCurrency, getTitle } from '../helper/formatter';
import { pathOr } from 'ramda';


const PFDetails = (props) => {
    const navigate = useNavigate()
    const policy = useSelector(state => state.Auth.policy)
    const pf=pathOr('',['premiumFinance'],policy)
    const repayment=pathOr('',['repayment'],policy)
    const bank=pathOr('',['bank'],policy)
    const [type, setType] = useState('Details');
    const getColor = (val) => {
        switch (val) {
            case 'overdue':
                return 'bounced';
            case 'paid':
                return 'paid';
            default:
                return 'default';
        }
    }
    const getBGColor = (val) => {
        switch (val) {
            case 'overdue':
                return 'bouncedBg';
            case 'paid':
                return 'paidBg';
            case 'pending':
                return 'pendingBg';
            default:
                return 'default';
        }
    }
    const nth = (d)=> {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
    }
    
    //alert(JSON.stringify(repayment.EMIDetails.filter((v)=>{return v.status=='paid'})))

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div className='h3' style={{marginBottom:'16px'}}>{"Premium Financing Details"}</div>

                
                    <PolicyCard />
                    
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'36px' }}>
                        <div onClick={()=>{setType('Details')}} className={'cursor body '+(type=='Details'?'activeTab':'inactiveTab')} style={{padding:'9px'}}>{"Loan details"}</div>
                        <div onClick={()=>{setType('EMI')}} className={'cursor body '+(type=='EMI'?'activeTab':'inactiveTab')} style={{padding:'9px'}}>{"EMI calendar"}</div>
                        <div onClick={()=>{setType('Transaction')}} className={'cursor body '+(type=='Transaction'?'activeTab':'inactiveTab')} style={{padding:'9px'}}>{"Transactions"}</div>
                    </div>
                    {type=='Details' && (
                    <div style={{ marginTop: '12px', background: colorsv1.grey[300], padding: '20px',borderRadius:'12px' }}>
                        <div className='h3 semiBoldFont'>{"Loan details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Loan Account Number"}</div>
                                <div className='h4'>{"ipf"}{ pathOr('',['policyNumber'],policy)}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Loan Amount"}</div>
                            <div className='h4'>{formatCurrency(pathOr(0,['loanAmount'],pf))}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Annual Interest"}</div>
                                <div className='h4'>{ pathOr(0,['interest'],pf)}{"%"}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Processing Fee (incl.GST)"}</div>
                            <div className='h4'>{formatCurrency(pathOr(0,['processingFee'],pf))}</div>
                        </div>
                        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div className='h4'>{"Loan Disbursed"}</div>
                            <div className='h4'>{"₹ 1,00,000"}</div>
                        </div> */}
                        <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Loan Tenure"}</div>
                            <div className='h4'>{pathOr(0,['tenure'],pf)}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"EMI Amount"}</div>
                            <div className='h4'>{formatCurrency(pathOr(0,['emi'],pf))}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Loan Start Date"}</div>
                            <div className='h4'>{pathOr(new Date(),['startDate'],repayment)}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className='h4'>{"Loan End Date"}</div>
                            <div className='h4'>{pathOr(new Date(),['endDate'],repayment)}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Bank Name"}</div>
                            <div className='h4'>{pathOr('',['bankName'],bank)}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Account Number"}</div>
                            <div className='h4'>{pathOr('',['accountNumber'],bank)}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className='h4'>{"IFSC"}</div>
                            <div className='h4'>{pathOr('',['ifsc'],bank)}</div>
                        </div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Loan Agreement"}</div>
                            <div>
                                <FileDownloadOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                            <div className='h4'>{"Assignment  Agreement "}</div>
                            <div>
                                <FileDownloadOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className='h4'>{"Loan Pledge  Agreement "}</div>
                            <div>
                                <FileDownloadOutlinedIcon style={{ color: colorsv1.primaryColor, margin: 'auto' }} />
                            </div>
                        </div>
                    </div>
                    )}

                    {type == 'EMI' && (
                        <div style={{ marginTop: '12px' }}>
                            {repayment.EMIDetails.map((data,index) => {
                                return (
<div className={getBGColor(pathOr('',['status'],data))} style={{ borderRadius: '12px', padding: '16px',marginBottom:'24px' }}>
                                        <div className={'h3 semiBoldFont '+getColor(pathOr('',['status'],data))}>{index+1}{ nth(index+1)}{" EMI"}</div>
                                <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                                    <div className='body'>{"EMI Due Date"}</div>
                                    <div className='h4'>{pathOr(new Date(),['date'],data)}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                                    <div className='body'>{"Status"}</div>
                                    <div className={'h4 '+getColor(pathOr('',['status'],data))}>{pathOr('',['status'],data)}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div className='body'>{"EMI Amount"}</div>
                                    <div className='h4'>{formatCurrency(pathOr(0,['amount'],data))}</div>
                                        </div>
                                        {pathOr('',['status'],data) == 'overdue' && (
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'16px'}}>
                                            <div className='body'>{"Bounce charges"}</div>
                                            <div className='h4'>{formatCurrency(pathOr(0,['bounceCharge'],data))}</div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'16px'}}>
                                            <div className='body'>{"+GST 18%"}</div>
                                            <div className='h4'>{formatCurrency(pathOr(0,['bounceChargeGst'],data))}</div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'16px'}}>
                                            <div className='body'>{"Late payment"}</div>
                                            <div className='h4'>{formatCurrency(pathOr(0,['latePaymentCharge'],data))}</div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'16px'}}>
                                            <div className='body'>{"Penalty charges"}</div>
                                            <div className='h4'>{formatCurrency(pathOr(0,['penaltyCharge'],data))}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                                )
                            })}

                        </div>
                    )}

                    {type == 'Transaction' && repayment.EMIDetails.filter((v) => { return v.status == 'paid' }).length>0 && (
                        <div style={{ marginTop: '12px', background: colorsv1.grey[300], padding: '20px', borderRadius: '12px' }}>
                             <div className='h3 semiBoldFont'>{"Transactions details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                            {repayment.EMIDetails.filter((v)=>{return v.status=='paid'}).map((data, index) => { 
                                return (
                                    <div>
                                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                                    <div className='body'>{"Transaction ID"}</div>
                                    <div className='h4'>{pathOr('',['paid','transactionId'],data)}</div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'20px' }}>
                                    <div className='body'>{"Amount"}</div>
                                    <div className='h4'>{formatCurrency(pathOr(0,['paid','amount'],data))}</div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                    <div className='body'>{"Date"}</div>
                                    <div className='h4'>{pathOr(new Date(),['paid','date'],data)}</div>
                                        </div>
                               </div>
                                )
                            })}
</div>
                    )}
               
                     {type == 'Transaction' && repayment.EMIDetails.filter((v) => { return v.status == 'paid' }).length == 0 && (
                        <div style={{ marginTop: '12px', background: colorsv1.grey[300], padding: '20px', borderRadius: '12px' }}>
                             <div className='h3 semiBoldFont'>{"Transactions details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '20px 0' }}></div>
                            <div>
                                   <div className='body'>{"No transactions found"}</div>
                               </div>
</div>
                    )}
               

                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    <Button onClick={()=>{navigate('/status');}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default PFDetails
import React, { useEffect } from 'react'
import Container from '@mui/material/Container';
import Home from './Home';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import colorsv1 from '../config/colorsv1';
import PolicyCard from '../components/PolicyCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { createEsign, getUserDetails } from '../actions/policyAction';
import { getTitle } from '../helper/formatter';


const Esign = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const policy = useSelector(state => state.Auth.policy)
    const esign = useSelector(state => state.Auth.esign)

//     const steps = useSelector(state => state.Auth.steps)
//  useEffect(() => {
//    const step = getStepsForNav(steps, policy, 'esign');
//    if(step!='')
//                             navigate(step)
//      }, [dispatch,steps])

    const handleEsign = async() => {
           const status = await dispatch(
          createEsign({policyId: pathOr('', ['_id'], policy)}),
           );
        if (status) {
           // navigate('/esignurl')
        }
    }
     useEffect(() => {
        if(pathOr('', ['_id'], policy)!='' && pathOr('', ['optFor'], policy)=='premiumFinance'){
            handleEsign();
        }
      }, [dispatch, policy._id]);
    
    const handleURL = () => {
        //alert(JSON.stringify(esign))
        // setTimeout(() => {
        //
            window.location.href = pathOr(pathOr('',[pathOr('',['optFor'],policy),'esignUrl'],policy),['url'],esign);
        // }, 2000);
        
        //navigate('/esignurl');
    }

              const handleAuthMe = async() => {
           const status = await dispatch(
          getUserDetails(),
           );
      
    }
      useEffect(() => {
          handleAuthMe();
      }, [dispatch]);

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h2'>{"e-Sign"}</div>
                    <div style={{ border: '0.5px solid #ABABAB',marginTop:'12px',marginBottom:'24px' }}></div>
                
                    <PolicyCard />
                    
                    <div style={{ display: 'flex', alignItems: 'center',borderRadius:'4px',background:'#F0F3FA',border:'1px solide #93BBD2',marginTop:'24px',padding:'8px 18px' }}>
                        <InfoOutlinedIcon style={{ color: colorsv1.notification.dark.info, margin: 'auto' }} />
                        <div style={{marginLeft:'12px'}}>
                            <div className='ten' style={{ marginBottom: '12px' }}>{"we kindly request you complete the Aadhaar-based e-sign process. The e-signature is a secure and convenient method for you to sign the required documents electronically."}</div>
                            {pathOr('', ['optFor'], policy) === 'assignment' && (
                                <div className='ten'>{"The policy will be absolutely assigned to lender"}</div>
                            )}
                            {pathOr('', ['optFor'], policy) !== 'assignment' && (
                                <div className='ten'>{"The policy will be conditionally assigned to lender"}</div>
                            )}
                            
                        </div>
                    </div>
                

                    
                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    {pathOr(pathOr('',[pathOr('',['optFor'],policy),'esignUrl'],policy),['url'],esign)!='' && pathOr('', ['bank','accountNumber'], policy)!='' && (
                            <Button onClick={() => { handleURL() }} variant="contained" color="success" size="medium" sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                            )}
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default Esign
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { FormControlLabel, Radio, RadioGroup, createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { formatCurrency, getTitle, maskBankAccNum } from '../helper/formatter';
import { pathOr } from 'ramda';


const EnachFailure = (props) => {
    const navigate = useNavigate()
    const policy = useSelector(state => state.Auth.policy)
    

    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
                    <div className='h3'>{"Setup auto - debit for EMIs"}</div>
                    <div className='body' style={{marginTop:'8px',marginBottom:'24px'}}>{"EMI of "+formatCurrency(pathOr(0,[pathOr('', ['optFor'], policy),'emi'],policy))+" will auto debited"}</div>
                
                
                    <PolicyCard />

                    <div style={{ marginTop:'24px',borderRadius: '12px', background: colorsv1.grey[300], padding: '16px' }}>
                        <div className='h3'>{"e-NACH: Bank details"}</div>
                        <div style={{ border: '0.5px solid #ABABAB', margin: '8px 0 16px 0' }}></div>
                        <div className='body'>{pathOr('',['bank','bankName'],policy)}</div>
                        <div className='body' style={{marginTop:'16px'}}>{"A/C "+maskBankAccNum(pathOr('',['bank','accountNumber'],policy))}</div>
                    </div>

                    <div style={{ background: colorsv1.grey[200], textAlign: 'center',marginTop:'38px',borderRadius:'12px',padding:'20px' }}>
                        <InfoOutlinedIcon fontSize="large" style={{ color: colorsv1.notification.dark.error, margin: 'auto' }} />
                        <div style={{ color: colorsv1.notification.dark.error, margin: '16px 0 10px 0' }}>{"e-Mandate Registration Failed"}</div>
                        <div>{"There is a problem with authentication using net banking or debit card."}</div>
                    </div>
                    
                


                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                    <Button onClick={()=>{navigate('/enach');}} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>{"Try again"}</Button>
                </div>

                </Paper>
            </Container>
        </Home>
    )
}

export default EnachFailure
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Home from './Home';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './index.css'

import { useNavigate } from 'react-router-dom'
import OtpInputs from 'react-otp-input';
import colorsv1 from '../config/colorsv1';
import Badge from '@mui/material/Badge';
import { Alert, Stack, createTheme } from '@mui/material';
import PolicyCard from '../components/PolicyCard';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { formatCurrency, getTitle } from '../helper/formatter';
import { getSteps } from '../helper/steps';
import { canProceed } from '../helper/canProceed';


const Intro = (props) => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState('');
    const platform = window.navigator.platform
    const steps = useSelector(state => state.Auth.steps)
    const policy = useSelector(state => state.Auth.policy)
    const user = useSelector(state => state.Auth.user)
    const [errorCam, setCamError] = useState(true)
        const [error, setError] = useState({
        isCamera: {
            hasError: platform == 'iPhone'?false:true,
            errorMessage: ''
        },
        })
    
    useEffect(() => {
        if (platform == 'iPhone') {
               setCamError(false)
            setError({
                ...error,
                isCamera: {
                    hasError: false,
                    errorMessage: '',
                },
            });
        } else {
             navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);

        navigator.getMedia({ video: true }, function () {
            // webcam is available
            setCamError(false)
            setError({
                ...error,
                isCamera: {
                    hasError: false,
                    errorMessage: '',
                },
            });
        }, function () {
            // webcam is not available
            setCamError(true)
            setError({
                ...error,
                isCamera: {
                    hasError: true,
                    errorMessage: '',
                },
            });
        });
          }
       
    }, [])


    return (
        <Home>
            <Container component="main" maxWidth="sm" sx={{ mb: 4,mt:10 }}>
                <Typography 
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                    sx={{ flex: 1 }}
                    className="title"
                >
                {getTitle(pathOr('', ['optFor'], policy))}
                </Typography>
                
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                 
 {errorCam && (
                    <div style={{ marginBottom: '18px' }}>
                        <Stack sx={{ width: '100%', marginBottom: '24px' }} spacing={2}>
                            <Alert variant="filled" severity="error">
                                There might be no web/phone camera - please enable
                            </Alert>
                        </Stack>
                    </div>
                )}
                
<PolicyCard/>
                
                    <div style={{marginTop:'15px',marginLeft:'15px'}}>
                        <Typography variant='h6' style={{color:colorsv1.primaryColor}}>{'Congratulations !'}</Typography>
                        <Typography variant='caption'>{'Your are Eligible to'}</Typography>
                        <Typography variant='subtitle1'>{'get immediate liquidity of '}<span style={{fontWeight:800}}>{`${formatCurrency(Math.floor(policy.surrenderValue))}`}</span>{' by assigning your policy'}</Typography>
                    </div>
                    {!policy.isCkycSuccess &&(
                     <div className='InsurType' style={{background:colorsv1.grey[300],padding:'16px',borderRadius:'11px',marginTop:'30px',justifyContent:'space-between',alignItems:'center'}}>
                        <div>
                            <div className='body mediumFont'>{"Complete your KYC"}</div>
                             {pathOr('', ['optFor'], policy) != 'assignment' && (
                                <div className='body lightFont' style={{ marginTop: '12px', marginBottom: '24px' }}>{"Verify all applicants to get your loan"}</div>
                            )}
                            {pathOr('', ['optFor'], policy) == 'assignment' && (
                                <div className='body lightFont' style={{ marginTop: '12px', marginBottom: '24px' }}>{"Verify all applicants to complete the assignment process"}</div>
                            )}
                            
                            <div style={{ display: 'flex',alignItems:'center' }}>
                                <div style={{background:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',marginRight:'10px'}} className='subTitles'>{1}</div>
                                <div className='body'>{'PAN'}</div>
                            </div>
                            <div style={{ display: 'flex',alignItems:'center' }}>
                                 <div style={{background:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',margin:'10px 10px 10px 0'}} className='subTitles'>{2}</div>
                                <div className='body'>{'KYC'}</div>  
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                 <div style={{background:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',marginRight:'10px'}} className='subTitles'>{3}</div>
                                <div className='body'>{'Bank Details'}</div>
                            </div>
                        </div>
                        <div>
                            <img style={{width:'160px',height:'108px'}}
                                src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/KYC.png'></img>
                        </div>
                    </div> 
                    )}

                    <div className='InsurType' style={{background:colorsv1.grey[300],padding:'16px',borderRadius:'11px',marginTop:'30px',justifyContent:'space-between',alignItems:'center'}}>
                        <div>
                            <div className='body mediumFont'>{"Approval"}</div>
                            {pathOr('', ['optFor'], policy) != 'assignment' && (
                                <div className='body lightFont' style={{ marginTop: '12px', marginBottom: '24px' }}>{"Verify all applicants to get your loan"}</div>
                            )}
                            {pathOr('', ['optFor'], policy) == 'assignment' && (
                                <div className='body lightFont' style={{ marginTop: '12px', marginBottom: '24px' }}>{"Verify all applicants to complete the assignment process"}</div>
                            )}
                            
                            <div style={{ display: 'flex',alignItems:'center' }}>
                                <div style={{background:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',marginRight:'10px',marginBottom:'10px'}} className='subTitles'>{1}</div>
                                <div className='body'>{'e-Sign'}</div>
                            </div>
                            {pathOr('', ['optFor'], policy) != 'assignment' && (
                                 <div style={{ display: 'flex',alignItems:'center' }}>
                                 <div style={{background:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',margin:'0px 10px 10px 0'}} className='subTitles'>{2}</div>
                                <div className='body'>{'e-Nach'}</div>
                            </div>
                            )}
                          
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                 <div style={{background:colorsv1.primary.light[200],borderRadius:'24px',padding:'8px 12px',marginRight:'10px'}} className='subTitles'>{pathOr('', ['optFor'], policy) != 'assignment'?3:2}</div>
                                <div className='body'>{getTitle(pathOr('', ['optFor'], policy))+' Approval'}</div>
                            </div>
                        </div>
                        <div>
                            <img style={{width:'112px',height:'112px'}}
                                src='https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/Approval.png'></img>
                        </div>
                    </div>

                     <div style={{marginTop:'24px',textAlign:'center',marginBottom:'32px'}}>
                        <Button disabled={canProceed(error)} onClick={() => {
                            const step = getSteps(steps,policy);
                            policy.isCkycSuccess ? navigate('/details') : navigate(step)
                            
                    }} variant="contained" color="success" size="medium"  sx={{ width: 260, padding: 1, margin: 2 }}>Next</Button>
                    </div>
                    

                </Paper>
            </Container>
        </Home>
    )
}

export default Intro